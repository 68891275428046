<template>
  <v-app class="grey lighten-3">
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container" style="width: 100%">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-text-field
            rounded
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <button @click="openDialog">
            <v-row align="center">
              <v-avatar
                ><v-img src="../assets/admin-logo.svg"></v-img
              ></v-avatar>

              <div class="mx-5 font-weight-medium" v-if="data != ''">
                Hai, {{ data.data_user.username }}!
              </div>
            </v-row>
          </button>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <component v-bind:is="layout" />
      </div>
    </v-main>

    <v-dialog v-model="dialogLogOut" width="400" :retain-focus="false">
      <v-card>
        <v-card-title class="text-h5"> Logout </v-card-title>

        <v-card-text> Are you sure to Logout </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mx-5"
            text
            @click="dialogLogOut = false"
          >
            No
          </v-btn>
          <v-btn color="primary" text @click="onLogOut"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue";
import DataTower from "../views/home/DataTower.vue";
import InitialView from "../views/home/InitialView.vue";
import AssetPerformance from "../views/admin/AssetPerformance.vue";
import ImprovementAsset from "../views/admin/ImprovementAsset.vue";

export default {
  components: {
    VerticalNavMenu,
    DataTower,
    InitialView,
    AssetPerformance,
    ImprovementAsset,
  },
  data: () => ({
    dialogLogOut: false,
    isDrawerOpen: true,
    data: [],
    layout: "InitialView",
  }),
  created() {
    this.$watch(
      () => {
        this.$route;
        return {};
      },
      () => {
        this.showContentBasedOnRouting();
      }
    );
  },
  methods: {
    showContentBasedOnRouting() {
      const route = this.$route;
      // console.log(route);
      const menu = route.params.menu;
      const subMenu = route.params.subMenu;

      if (menu == "data-master") {
        switch (subMenu) {
          case "tower":
            this.layout = "DataTower";
            break;

          default:
            this.layout = "InitialView";
            break;
        }
      } else {
        this.layout = "InitialView";
      }

      if (menu == "asset-management") {
        switch (subMenu) {
          case "asset-performance":
            this.layout = "AssetPerformance";
            break;

          case "loss-registration":
            this.layout = "AssetPerformance";
            break;
          case "improvement-asset":
            this.layout = "ImprovementAsset";
            break;

          default:
            this.layout = "InitialView";
            break;
        }
      } else {
        this.layout = "InitialView";
      }
    },

    openDialog() {
      this.dialogLogOut = true;
    },
    onLogOut() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem("data"));
    this.showContentBasedOnRouting();
  },
};
</script>
