<template>
  <v-app>
    <v-card class="d-flex flex-row rounded-0" flat>
      <v-card width="43%" class="dark-blue d-flex flex-column justify-start">
        <v-img
          src="@/assets/logo.svg"
          max-width="220"
          max-height="110"
          class="ml-10 mt-10"
          flat
        >
        </v-img>
        <h1 class="white--text ml-14 mt-15 mb-6">Login To Your Account</h1>

        <v-form ref="form" @submit.prevent>
          <v-text-field
            v-model="username"
            :rules="requiredRules"
            color="white"
            clearable
            rounded
            filled
            dark
            label="Username"
            placeholder="Type Your Username"
            class="pl-13 py-2 mb-4 radius"
          ></v-text-field>

          <v-text-field
            id="password"
            name="password"
            v-model="password"
            :rules="requiredRules"
            :type="show ? 'password' : 'text'"
            :append-icon="!show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            color="white"
            rounded
            filled
            dark
            label="Password"
            placeholder="Type Your Password"
            class="pl-13 radius"
          ></v-text-field>
        </v-form>
        <p class="forgot-password d-flex justify-end m-0 py-4 white--text">
          Forgot Password?
        </p>
        <v-btn
          @submit.prevent
          class="ml-14 py-9 rounded-lg my-2 primary"
          @click="login"
        >
          <p class="pt-3 font-size">Log in</p>
        </v-btn>
      </v-card>
      <v-img
        src="@/assets/kievit.svg"
        height="100vh"
        gradient="70deg,
        #1a194f 30.5%,
         rgba(29, 28, 81, 0.55) 80%"
      >
      </v-img>
    </v-card>
  </v-app>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus";

export default {
  name: "Login",
  data: () => ({
    show: true,
    username: "",
    password: "",
    requiredRules: [(v) => !!v || "Field is required"],
  }),
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("startLoading");

        try {
          const resLogin = await axios.post(`${this.$api}/login`, {
            username: this.username,
            password: this.password,
          });
          if (resLogin.status == 200) {
            localStorage.setItem("data", JSON.stringify(resLogin.data));
            this.$router.push({ path: "/" });
          }
        } catch (err) {
          var error = err;
          if (err.response.data.message) {
            error = err.response.data.message;
          }
          EventBus.$emit("showSnackbar", error, "red");
        }

        EventBus.$emit("stopLoading");
      }
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: min(3.8vw, 1.35rem);
  font-weight: 600;
}

.radius {
  border-radius: 20px !important;
}

.forgot-password {
  cursor: pointer;
  font-size: min(3vw, 1.15rem);
  font-weight: 400;
  color: white;
}

.fill {
  object-fit: fill;
}

.font-size {
  font-size: min(3vw, 1.3rem);
}

h1 {
  white-space: pre;
  margin: auto;
  font-size: min(4vw, 2.5rem);
  font-weight: 600;
}

.dark-blue {
  background: #1a194f;
}
</style>
