<template>
  <div>
    <v-row :key="keyCounter">
      <v-col class="col-10" v-if="keyCounter != 0">
        <v-row>
          <v-col
            :class="
              menuContent.childrens.length > 1 ? 'col-6 max-width' : 'col-12'
            "
            class="p-0 mb-3"
            v-for="item in menuContent.childrens"
            :key="item.id"
          >
          <h3 class="mb-2">{{ humanize(item.route.data) }}</h3>
            <ChartData
              :hasFilterProp="true"
              :towerProp="chartFiltersValue.tower"
              :yearProp="chartFiltersValue.year"
              :monthProp="chartFiltersValue.month"
              :picProp="chartFiltersValue.pic"
              :logProp="chartFiltersValue.log"
              :ccProp="chartFiltersValue.ccName"
              :titleProp="chartFiltersValue.title"
              :routeProp="item.route"
              :key="item.id"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" align-self="start" class="mt-10">
        <div v-if="chartFiltersData.towers.length > 0" class="mb-2 mt-5">
          <div>Tower:</div>
          <v-select
            :items="chartFiltersData.towers"
            item-text="name"
            item-value="vcname"
            v-model="chartFiltersValue.tower"
            label="Tower"
            @change="changeData()"
            solo
            background-color="#F6F6FB"
            flat
            dense
          ></v-select>
        </div>
        <div
          v-if="chartFiltersData.years.length > 0"
          class="mb-2"
          :class="'mt-5'"
        >
          <div>Year:</div>
          <v-select
            :items="chartFiltersData.years"
            v-model="chartFiltersValue.year"
            label="Year"
            solo
            @change="changeData()"
            background-color="#F6F6FB"
            flat
            dense
          ></v-select>
        </div>

        <div v-if="chartFiltersData.pics.length > 0">
          <div>PIC/Zone:</div>
          <v-select
            v-if="chartFiltersData.pics"
            :items="chartFiltersData.pics"
            item-text="vcname"
            item-value="vcname"
            v-model="chartFiltersValue.pic"
            label="PIC/Zone"
            solo
            @change="changeData()"
            background-color="#F6F6FB"
            flat
            dense
          ></v-select>
        </div>

        <div v-if="chartFiltersData.months.length > 0" class="mb-2">
          <div>Month</div>
          <v-select
            :items="chartFiltersData.months"
            v-model="chartFiltersValue.month"
            label="Month"
            solo
            @change="changeData()"
            background-color="#F6F6FB"
            flat
            dense
          ></v-select>
        </div>

        <div class="" v-if="chartFiltersData.ccNames.length > 0">
          <div>CC Name:</div>
          <v-select
            :items="chartFiltersData.ccNames"
            item-text="vcname"
            item-value="vcname"
            v-model="chartFiltersValue.ccName"
            label="CC Name"
            solo
            @change="changeData()"
            background-color="#F6F6FB"
            flat
            dense
          ></v-select>
        </div>

        <div class="" v-if="chartFiltersData.titles">
          <div>Title:</div>
          <v-select
            :items="chartFiltersData.titles"
            item-text="vcjudul"
            v-model="chartFiltersValue.title"
            label="Title"
            solo
            @change="changeData()"
            background-color="#F6F6FB"
            flat
            dense
          ></v-select>
        </div>

        <div v-if="chartFiltersValue.backlog">Status BackLog:</div>
        <v-switch
          class="ml-3"
          v-model="chartFiltersValue.backlog"
          @change="changeData()"
          true-value="1"
          false-value="0"
          v-if="chartFiltersValue.backlog"
          hide-details
          inset
        ></v-switch>

        <div>
          Hide Bottom Button:
          <v-switch
            class="ml-3"
            :true-value="true"
            :false-value="false"
            @change="changeHideMenu()"
            v-model="hideBottomButton"
            hide-details
            inset
          ></v-switch>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from "../../event-bus";
import ChartData from "./ChartDataView.vue";

export default {
  name: "AllChart",
  components: {
    ChartData,
  },
  data: () => ({
    menuContent: [],
    keyCounter: 0,
    menu: "",
    subMenu: "",
    hideBottomButton: false,
    chartFiltersValue: {
      tower: false,
      year: false,
      month: false,
      pic: false,
      ccName: false,
      backlog: false,
      title: false,
    },
    chartFiltersData: {
      towers: [],
      years: [],
      months: [],
      pics: [],
      ccNames: [],
      titles: [],
    },
  }),
  async created() {
    this.$watch(
      () => {
        this.$route.params;
        return {};
      },
      () => {
        this.getDefaultData();
      }
    );
  },

  mounted() {
    this.getDefaultData();
  },
  methods: {
    humanize(str) {
      var i,
        frags = str.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    changeHideMenu() {
      EventBus.$emit("setHideMenu", this.hideBottomButton);
    },
    changeData() {
      var title = this.chartFiltersValue.title;
      this.keyCounter++;
      if (this.chartFiltersData.titles) {
        if (title) {
          if (!title.includes(this.chartFiltersValue.year)) {
            this.getTitles();
          }
        } else {
          this.getTitles();
        }
      }
    },
    async getDefaultData() {
      console.log("get default data");
      const data = JSON.parse(localStorage.getItem("data"));
      this.menuContent = data.data[1]["menu-content"];
      console.log(this.$route.params);
      this.menu = this.$route.params.menu;
      this.subMenu = this.$route.params.subMenu;
      this.menuContent = this.menuContent.find(
        (o) => o.vcurl === `/chart/${this.menu}`
      );
      this.menuContent = this.menuContent.childrens.find(
        (o) => o.vcurl === `/chart/${this.menu}/${this.subMenu}`
      );
      console.log("length 1");
      for (var i = 0; i < this.menuContent.childrens.length; i++) {
        this.menuContent.childrens[i].route = {
          menu: this.menu,
          subMenu: this.subMenu,
          data: this.menuContent.childrens[i].vcurl.split("/")[4],
        };
      }
      console.log("menu content", this.menuContent);

      EventBus.$emit("showLoading");
      await this.initFilterData();
      EventBus.$emit("stopLoading");
    },
    async getListZones() {
      this.chartFiltersData.pics = JSON.parse(localStorage.getItem("pics"));
      console.log("length 2");
      if (this.chartFiltersData.pics.length > 0) {
        this.chartFiltersValue.pic = this.chartFiltersData.pics[0].vcname;
      }
    },
    getTitles() {
      this.chartFiltersData.titles = JSON.parse(localStorage.getItem("titles"));
      console.log("length 3", this.chartFiltersData.titles.length);
      if (this.chartFiltersData.titles.length > 0) {
        // only retrieve titles with current year
        var tempTitles = [];
        tempTitles.push(...this.chartFiltersData.titles);
        console.log("temp titles", tempTitles);

        if (this.chartFiltersValue.year === 0) {
          console.log("debug 1");
          var years = tempTitles[tempTitles.length - 1]["vcjudul"].split(" ");
          var year = years[years.length - 1];
          this.chartFiltersValue.year = year;
        }

        for (var i = 0; i < tempTitles.length; i++) {
          var data = tempTitles[i];
          try {
            console.log("debug 2");
            var words = data["vcjudul"].split(" ");
            var word = words[words.length - 1];
            if (this.chartFiltersData.years.indexOf(word) === -1) {
              this.chartFiltersData.years.push(word);
            }
            if (word != `${this.chartFiltersValue.year}`) {
              console.log("debug 3");
              const isSameTitle = (element) =>
                element["vcjudul"] == data["vcjudul"];
              var index = this.chartFiltersData.titles.findIndex(isSameTitle);
              console.log("index " + index);
              this.chartFiltersData.titles.splice(index, 1);
            }
          } catch (_) {
            console.log(data);
            console.log("error in catch: " + _);
          }
        }
        console.log("debug 4");
        this.chartFiltersValue.title =
          this.chartFiltersData.titles[this.chartFiltersData.titles.length - 1][
            "vcjudul"
          ];
        var title = this.selectedTitle.split(" ");
        this.chartFiltersValue.year = title[words.length - 1];
      }
    },

    async getListTowers() {
      this.chartFiltersData.towers = [
        {
          name: "All Tower",
          value: 0,
        },
        {
          name: "Tower S1",
          value: 1,
        },
        {
          name: "Tower S2",
          value: 2,
        },
      ];
      this.chartFiltersValue.tower = this.chartFiltersData.towers[0].value;
    },

    async getListCcNames() {
      this.chartFiltersData.ccNames = JSON.parse(
        localStorage.getItem("ccNames")
      );
      console.log("length 4");
      if (this.chartFiltersData.ccNames.length > 0) {
        this.chartFiltersValue.ccName = this.chartFiltersData.ccNames[0].vcname;
      }
    },

    async initFilterData() {
      console.log("init filter data start");
      this.chartFiltersValue = {
        tower: false,
        year: false,
        month: false,
        pic: false,
        ccName: false,
        backlog: false,
      };
      this.chartFiltersData = {
        towers: [],
        years: [],
        months: [],
        pics: [],
        ccNames: [],
      };
      switch (this.subMenu) {
        // Tower, Year
        case "asset-performance":
        case "loss-analysis":
          await this.getListTowers();
          this.getYears();
          break;
        // Tower, Year, Month
        case "loss-registration":
          await this.getListTowers();
          this.getYears();
          this.getMonths();
          break;
        // Year, PIC, Backlog
        case "job-execution":
          this.getYears();
          await this.getListZones();
          this.backlog = 0;
          break;
        // Year, PIC
        case "planning-schedule":
          this.getYears();
          await this.getListZones();
          break;
        // Year, CC Name, Zone
        case "asset-cost-budgeting":
        case "cost-analysis":
          this.getYears();
          await this.getListCcNames();
          await this.getListZones();
          break;
        // Year, Title
        case "improvement-asset":
          this.getYears();
          this.getTitles();
          break;
      }
      this.changeData();

      console.log("init filter data", this.chartFiltersData);
      console.log("key", this.keyCounter);
    },

    getYears() {
      var d = new Date();
      for (var i = 2014; i <= d.getFullYear(); i++) {
        this.chartFiltersData.years.push(i);
      }
      this.chartFiltersData.years.reverse();
      this.chartFiltersValue.year = this.chartFiltersData.years[0];
    },

    getMonths() {
      this.chartFiltersData.months = [
        {
          text: "January",
          value: 1,
        },
        {
          text: "February",
          value: 2,
        },
        {
          text: "March",
          value: 3,
        },
        {
          text: "April",
          value: 4,
        },
        {
          text: "May",
          value: 5,
        },
        {
          text: "June",
          value: 6,
        },
        {
          text: "July",
          value: 8,
        },
        {
          text: "August",
          value: 8,
        },
        {
          text: "September",
          value: 9,
        },
        {
          text: "October",
          value: 10,
        },
        {
          text: "November",
          value: 11,
        },
        {
          text: "December",
          value: 12,
        },
      ];
      this.chartFiltersValue.month = this.chartFiltersData.months[0];
    },
  },
};
</script>

<style scoped>
.max-width {
  max-width: 50% !important;
}
</style>
