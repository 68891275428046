<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="350"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/menu-admin"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/logo.png')"
          max-height="60px"
          max-width="60px"
          alt="logo"
          contain
          eager
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">Kievit</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-group
        class="mb-3"
        :title="data.vcname"
        v-for="data in navData"
        :key="data.id"
      >
        <nav-menu-link
          class="primary--text"
          :title="childData.vcname"
          v-for="childData in data.childrens"
          :key="childData.id"
          :link="`/menu-admin${childData.vcurl}`"
        ></nav-menu-link>
      </nav-menu-group>
      <v-divider
        class="primary ml-16 mr-2 mt-6"
        style="padding: 0.05rem"
      ></v-divider>
      <nav-menu-link
        class="primary--text mt-10"
        title="Back to Home"
        link="/"
        icon="mdi-exit-to-app"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuGroup from "./components/NavMenuGroup.vue";
import NavMenuLink from "./components/NavMenuLink.vue";
import axios from "axios";
import { EventBus } from "../../../event-bus";

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
  },
  data: () => ({
    isDrawerOpen: true,
    navData: [],
  }),
  async mounted() {
    EventBus.$emit("startLoading");
    const data = JSON.parse(localStorage.getItem("data"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${data.access_token}`,
    };
    const navData = await axios.get(`${this.$api}/menu-admin`);
    this.navData = navData.data.data;
    EventBus.$emit("stopLoading");
  },
};
</script>

<style scoped>
.app-navigation-menu {
  border-radius: 0px 20px 20px 0px;
}
</style>
