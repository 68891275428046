<template>
  <div
    class="bottom-component-item item-border"
    :class="active ? 'bottom-component-item-active' : 'bottom-component-item'"
    :style="{ width: item.vcname == 'Dashboard' ? '122px' : (active ? '210px' : '200px') }"
  >
    <div style="position: absolute; width: 100%; height: 100%">
      <div v-if="item.vcname != 'Dashboard'">
        <div class="item-property" style="height: 62px">
          <v-icon class="item-position" :color="active ? 'white' : 'primary'" size="50">{{
            item.vcicon == 'null' || item.vcicon == '' ? 'pe-7s-help1' : item.vcicon
          }}</v-icon>
        </div>
        <div
          class="
            primary--text
            font-weight-medium
            text-center
            item-property
            mx-auto
          "
          style="height: 60px; width: 90%"
        >
          <div class="item-position mb-3" :class="active ? 'white--text' : 'primary--text'">{{ item.vcname }}</div>
        </div>
      </div>
      <div style="width: 100%; height: 100%" v-else>
        <v-icon class="absolute-center" :color="active ? 'white' : 'primary'" size="50">{{
          item.vcicon == 'null' || item.vcicon == '' ? 'pe-7s-help1' : item.vcicon
        }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomComponentItem",
  props: ["item", "active"],
};
</script>

<style scoped>
.bottom-component-item {
  height: 130px;
  position: relative;
  cursor: pointer;
}

.bottom-component-item-active {
  height: 150px;
  position: relative;
  cursor: pointer;
}

.item-position {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.item-property {
  position: relative;
}
</style>

<style>
.vhl-item {
  width: auto !important;
}
</style>
