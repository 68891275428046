<template>
  <div>
    <v-row>
      <v-col :class="!hasFilterProp ? '12' : '10'">
        <div
          class="d-flex flex-row align-center justify-center primary--text font-weight-medium"
        >
          <p>{{ subtitle }}</p>
        </div>
        <div :class="hasFilterProp ? '' : 'd-flex flex-row'" class="justify-center align-center">
          <v-card
            width="1000"
            class="elevation-0 pb-3"
            v-for="(series, i) in chartData"
            :key="i"
          >
            <v-card-title> {{ i }} </v-card-title>
            <div>
              <div v-if="chartData[i][1][1] <= 0">
                <pie-chart
                  :library="options"
                  :data="
                    route.data == 'pmorder-ratio' ||
                    route.data == 'work-preparation'
                      ? chartData[i]
                      : [['M&R COST', number1]]
                  "
                  :donut="true"
                  :colors="colors"
                  adapter="google"
                >
                </pie-chart>
              </div>

              <div
                v-if="
                  chartData[i][0][1] >= 0 &&
                  chartData[i][1][1] >= 0 &&
                  chartData[i][1][1] !== 0
                "
              >
                <pie-chart
                  :library="options"
                  :data="chartData[i]"
                  :donut="true"
                  :colors="colors"
                  adapter="google"
                >
                </pie-chart>
              </div>

              <v-card-text
                class="title-data text-center font-weight-bold black--text pb-0"
                v-if="route.data == 'ytd-realization'"
              >
                {{ chartData[i][1][1] }}
              </v-card-text>

              <v-card-text
                class="title-data text-center font-weight-bold black--text"
                v-if="route.data == 'ytd-realization'"
              >
                {{ chartData[i][0][1] }}
              </v-card-text>
            </div>
          </v-card>

          <div
            v-if="route.data == 'ytd-realization'"
            class="container-speedometer"
            :class="hasFilterProp ? 'mt-16' : ''"
          >
            <div class="position-absolute">
              <vue-speedometer
                :width="460"
                :needleHeightRatio="0.5"
                :maxSegmentLabels="11"
                :segments="11"
                :value="gaugeData[1][1] > 50 ? 50 : gaugeData[1][1]"
                :minValue="-50"
                :maxValue="50"
                :segmentColors="['#EB7853', '#ECEFF4']"
                needleColor="#fff"
                valueTextFontSize="0px"
                textColor="${textColor}"
              />
            </div>
            <div class="position-absolute">
              <vue-speedometer
                :width="460"
                :needleHeightRatio="0.5"
                :value="gaugeData[1][1] > 50 ? 50 : gaugeData[1][1]"
                :minValue="-50"
                :maxValue="50"
                :customSegmentStops="[-50, 0, 50]"
                :segmentColors="['#17a2b8', '#dc3545']"
                :currentValueText="`${gaugeData[1][1]}%`"
                valueTextFontSize="22px"
                :customSegmentLabels="[
                  {
                    text: 'Lower Budget',
                    color: '#fff',
                  },
                  {
                    text: 'Over Budget',
                    color: '#fff',
                  },
                ]"
                :needleTransitionDuration="3333"
                needleTransition="easeElastic"
                needleColor="#0062cc"
                textColor="#000"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="!hasFilterProp"
        cols="2"
        align-self="start"
        class="mt-10 comment-position"
      >
        <v-btn
          @click.stop="drawer = !drawer"
          color="primary"
          class="text-none"
          style="border-radius: 10px"
          elevation="0"
          >Comment<v-icon right dark> mdi-comment </v-icon></v-btn
        >

        <div class="mt-5">Year:</div>
        <v-select
          v-if="year"
          :items="years"
          v-model="selectedYear"
          label="Year"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>

        <div class="" v-if="pics">PIC:</div>
        <v-select
          v-if="pics"
          :items="pics"
          item-text="vcname"
          item-value="vcname"
          v-model="selectedPic"
          label="PIC"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
        <div class="" v-if="ccNames">CC Name:</div>
        <v-select
          v-if="ccNames"
          :items="ccNames"
          item-text="vcname"
          item-value="vcname"
          v-model="selectedCc"
          label="CC Name"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
      </v-col>
      <v-navigation-drawer
        v-if="!hasFilterProp"
        width="324"
        v-model="drawer"
        right
        absolute
        temporary
      >
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-row">
              <v-list-item-title class="weight-600"
                >Comment Rooms</v-list-item-title
              >

              <v-btn icon @click="closeDrawer">
                <v-icon> mdi-window-close </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list dense>
          <v-list-item-content>
            <div
              class="d-flex flex-row"
              v-for="(comment, i) in userComments"
              :key="i"
            >
              <img src="@/assets/user.png" class="smol-img ml-4" />
              <div class="d-flex flex-column ml-4">
                <p class="mb-2 weight-600">{{ comment.userName }}</p>
                <p>{{ comment.createdAt }}</p>
                <p class="">{{ comment.comments }}</p>
              </div>
            </div>
          </v-list-item-content>
        </v-list>

        <template v-slot:append>
          <div class="text-center">
            <v-pagination
              @input="onChangePagination"
              :total-visible="5"
              v-model="paginationCounter"
              :length="paginationLength"
            ></v-pagination>
          </div>

          <div class="pa-2">
            <v-text-field
              v-model="form.txtComment"
              @click:append="add"
              append-icon="mdi-send"
              label="Type Your Comment"
              outlined
            >
            </v-text-field>
          </div>
        </template>
      </v-navigation-drawer>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import axios from "axios";
import VueSpeedometer from "vue-speedometer";

export default {
  name: "ChartPie",
  components: {
    VueSpeedometer,
  },
  props: [
    "routeProp",
    "towerProp",
    "yearProp",
    "monthProp",
    "picProp",
    "logProp",
    "ccProp",
    "titleProp",
    "hasFilterProp",
  ],
  data: () => ({
    number1: null,
    number2: null,
    paginationCounter: 1,
    paginationLength: 1,
    year: true,
    pic: true,
    drawer: null,
    userComments: [],
    form: {
      txtComment: "",
    },
    subtitle: "",
    chartData: {},
    ccNames: [],
    options: {
      annotations: {
        textStyle: {
          color: "black",
        },
        alwaysOutside: true,
      },
      backgroundColor: "transparent",
      cutoutPercentage: 90,
      series: {},
      focusTarget: "category",
      legend: {
        position: "bottom",
        textStyle: {
          fontSize: 16,
        },
      },
      isStacked: true,
    },
    colors: ["#1890FF", "#8543E0", "#4AC76F", "#F7CF33", "#EE5F77"],
    pics: false,
    years: [],
    selectedPic: 0,
    selectedYear: 2022,
    selectedCc: 1,
    gaugeData: [],
    route: {},
    apiRequestTimer: null,
  }),

  async created() {
    var d = new Date();
    this.selectedTower = 0;
    this.selectedYear = d.getFullYear();

    this.$watch(
      () => {
        this.$route;
        return {};
      },
      async () => {
        this.getDefaultData();
      }
    );
  },
  async mounted() {
    console.log("pie mounted");
    this.apiRequestTimer = setTimeout(async () => {
      this.getDefaultData();
    }, 1000);
  },
  beforeDestroy() {
    clearTimeout(this.apiRequestTimer);
  },

  methods: {
    async getDefaultData() {
      this.chartData = [];
      this.route = this.$route.params;
      if (this.routeProp) {
        this.route = this.routeProp;
      }
      this.setTitle();
      this.loaded = true;
      var d = new Date();
      this.selectedYear = d.getFullYear();
      for (var i = 2014; i <= d.getFullYear(); i++) {
        this.years.push(i);
      }
      this.years.reverse();
      if (!this.hasFilterProp) {
        await this.getList();
        await this.loadComment();
      }
      this.changeData();
    },
    setTitle() {
      var route = this.route;
      var localStorageData = JSON.parse(localStorage.getItem("data"));
      localStorageData = localStorageData["data"][1]["menu-content"].find(
        (e) => e.vcurl == `/chart/${route.menu}`
      );
      localStorageData = localStorageData["childrens"].find(
        (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}`
      );

      this.title = localStorageData.vcname;
      EventBus.$emit("changeTitle", this.title);

      localStorageData = localStorageData["childrens"].find(
        (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}/${route.data}`
      );

      this.subtitle = localStorageData.vcname;
    },
    changeData() {
      this.setData();
    },
    humanize(str) {
      var i,
        frags = str.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    async getList() {
      switch (this.route.data) {
        case "ytd-realization":
          await this.getListCcNames();
          this.pics = null;
          break;
        case "pmorder-ratio":
          await this.getListCcNames();
          await this.getListZones();
          break;
        default:
          await this.getListZones();
          this.ccNames = null;
      }
    },
    async setData() {
      this.gaugeData = [];
      var route = this.route;
      if (route.data == "pmorder-ratio" && this.selectedPic == 0)
        this.selectedPic = 1;

      if (this.hasFilterProp) {
        this.selectedYear = this.yearProp;
        this.selectedPic = this.picProp;
        this.selectedCc = this.ccProp;
      }

      const params = {
        year: this.selectedYear,
        pic: this.selectedPic == 'All PIC' ? undefined : this.selectedPic,
        zone: this.selectedPic == 'All PIC' ? undefined : this.selectedPic,
        ccname: this.selectedCc == 'All CCName' ? undefined : this.selectedCc,
      };
      this.subtitle = this.humanize(route.data);
      if (route.data == "base-tasktime") {
        this.subtitle = "Based on Task and Time";
        this.options["legend"] = {
          position: "right",
        };
      }
      this.setTitle();
      if (route.data == "ytd-realization") {
        this.colors = ["#1890FF", "#EB7853"];
      }

      // if (route.data == "pmorder-ratio") {
      //   this.options["legend"] = {
      //     position: "right",
      //   };
      // }
      try {
        console.log('PARAMSSS', params);
        const res = await axios.get(
          `${this.$api}/chart/${route.menu}/${route.subMenu}/${route.data}`,
          { params }
        );
        // console.log(res);
        var dataObject = {};
        var dataKievit = res.data.data;
        var data = dataKievit;
        this.gaugeData = [["Label", "Value"]];
        if (route.data == "ytd-realization") {
          dataKievit = [data[1]];
          for (const value of dataKievit) {
            for (const valueIn of value.chart) {
              this.gaugeData.push([
                "Budget",
                (valueIn.budget * 100).toFixed(0),
              ]);
            }
          }
        }

        dataKievit = data;
        if (route.data == "ytd-realization") dataKievit = [data[0]];

        var pmObject = new Array();

        // console.log("testing 1", dataKievit);

        // dataKievit.forEach((value) => {
        //   console.log(value);
        // });
        dataKievit.forEach((value) => {
          // console.log(value);
          var object = new Array();
          if (route.data == "pmorder-ratio") {
            pmObject.push([value.name, value.value]);
          } else {
            console.log("dataKievit", dataKievit);

            console.log("value", value);
            var chart =
              route.data == "base-tasktime"
                ? Object.values(value.chart.detail)
                : Object.values(value.chart);
            console.log(chart);

            chart.forEach((valueIn) => {
              console.log("value in", valueIn);
              switch (route.data) {
                case "base-tasktime":
                  object.push([valueIn.category, valueIn.value]);
                  break;
                case "ytd-realization":
                  object.push([valueIn.legend, valueIn.detail.value]);
                  console.log("object", object);
                  this.number1 = object[0][1];
                  console.log(object[0][1]);
                  // this.number2 = object[1][1];
                  // console.log(object[1][1]);

                  break;
                default:
                  object.push([valueIn.point, valueIn.detail.value]);
              }
            });
            dataObject[value.series] = object;
            this.chartData = dataObject;
          }
        });

        if (route.data == "pmorder-ratio") {
          dataObject["PM Order Ratio"] = pmObject;
          this.chartData = dataObject;
        }
      } catch (err) {
        var error = err;
        console.log(err);
        if (err.response.data.message) {
          error = err.response.data.message;
        }
        EventBus.$emit("showSnackbar", error, "red");
      }

      console.log("test gauge data", this.gaugeData);
    },

    async getListZones() {
      this.pics = JSON.parse(localStorage.getItem("pics"));
    },

    async getListCcNames() {
      this.ccNames = JSON.parse(localStorage.getItem("ccNames"));
    },

    async loadComment() {
      try {
        const response = await axios.get(`${this.$api}/chart/comments/7`);
        const data = response.data.data.comments;
        const length_data = response.data.data.last_page;
        this.paginationLength = length_data;
        data.forEach((comment) => {
          this.userComments.push({
            comments: comment.comment,
            userName: comment.user,
            createdAt: comment.created_at,
          });
        });
      } catch {
        console.log("ra iso");
      }
    },

    async add() {
      try {
        await axios.post(
          `${this.$api}/chart/comments/7?txtcomment=${this.form.txtComment}`
        );
        this.form.txtComment = "";
        this.onChangePagination(this.paginationLength);
      } catch (err) {
        console.log(err);
      }
    },

    async onChangePagination(number) {
      if (number) {
        try {
          const response = await axios.get(
            `${this.$api}/chart/comments/7?page=${number}`
          );
          this.userComments.length = 0;
          const data = response.data.data.comments;

          data.forEach((comment) => {
            this.userComments.push({
              comments: comment.comment,
              userName: comment.user,
              createdAt: comment.created_at,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
.container-speedometer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-relative {
  position: relative !important;
}

.container-absolute {
  position: absolute !important;
}

.position-absolute {
  position: absolute !important;
}

.shadow {
  box-shadow: 0px 4px 15px rgba(138, 138, 138, 0.1);
}
</style>

<style scoped>
.title-data {
  z-index: 1000;
  transform: translate(0, -12rem);
  font-size: 1.5rem !important;
}

.chart-card {
  background: #f9fafb !important;
}

rect[stroke-opacity] {
  stroke-width: 0 !important;
}
.status {
  border-radius: 4px;
  width: min-content;
  padding: 4px 8px;
}

.Controlable {
  background: #e5e7eb;
  border: 1px solid #9ca3af;
}

.Monitoring {
  background: #fef9c3;
  border: 1px solid #f59e0b;
}

.shadow {
  box-shadow: 0px 4px 15px rgba(138, 138, 138, 0.1);
}

.smol-img {
  height: 3rem;
  width: 2.8rem;
}

.weight-600 {
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
::v-deep rect[stroke-opacity] {
  stroke-width: 0px !important;
}

::v-deep th {
  background-color: #eb5353 !important;
  color: white !important;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
</style>
