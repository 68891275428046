<template>
  <v-app>
    <v-dialog v-model="loading" fullscreen transition="fade-transition">
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-main class="pa-0" :class="isPDCA ? 'disable-scrolling' : 'fill-height'">
      <router-view class="main-height" id="chart-id" />
    </v-main>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { EventBus } from "./event-bus";
import axios from "axios";
import store from "./store";

export default {
  name: "App",
  data: () => ({
    loading: false,
    snackbar: false,
    snackbarColor: "green",
    text: "",
    isPDCA: false,
  }),
  methods: {
    checkIsPDCA() {
      this.isPDCA = this.$route.params.data == "pdca-improvement";
    },
    async getListTitles() {
      try {
        const res = await axios.get(`${this.$api}/list/titles`);
        const data = res.data.data;
        localStorage.setItem("titles", JSON.stringify(data));
        store.dispatch("setTitles", data);
        console.log("titles from app", JSON.parse(localStorage.getItem("titles")));
      } catch (error) {
        console.log(error);
      }
    },
    async getListCcNames() {
      try {
        const res = await axios.get(`${this.$api}/list/ccnames`);
        const data = res.data.data;
        store.dispatch("setCcNames", data);
        localStorage.setItem("ccNames", JSON.stringify(data));
        console.log("cc names from app", JSON.parse(localStorage.getItem("ccNames")));
      } catch (error) {
        console.log(error);
      }
    },

    async getListZones() {
      try {
        const res = await axios.get(`${this.$api}/list/zones`);
        if (res.status == 404) {
          EventBus.$emit("showSnackbar", "No data available", "red");
        }
        const data = res.data.data;
        store.dispatch("setPics", data);
        localStorage.setItem("pics", JSON.stringify(data));
        console.log("zones or pics from app", JSON.parse(localStorage.getItem("pics")));
      } catch (error) {
        console.log(error);
      }
    },

    async triggerDefaultMethod() {
      console.log('trigger default method');
      var hasAccessToken = false;
      this.loading = true;
      var data = JSON.parse(localStorage.getItem("data"));
      if (data) {
        if (data.access_token) {
          axios.defaults.headers.common = {
            Authorization: `Bearer ${data.access_token}`,
          };
          hasAccessToken = true;
        }
      }

      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (
            (error.response.status === 401 || error.response.status === 403) &&
            window.location.pathname != "/login"
          ) {
            localStorage.clear();
            let hostDestination =
              window.location.hostname === "localhost"
                ? `localhost:${window.location.port}`
                : window.location.hostname;

            window.location.href = `http://${hostDestination}`;
          }
          return Promise.reject(error);
        }
      );

      if (hasAccessToken) {
        try {
          this.loading = true;
          await this.getListCcNames();
          await this.getListZones();
          await this.getListTitles();
        } catch (e) {
          console.log(e);
        }
      }
      this.loading = false;
    },
  },
  created() {
    this.$watch(
      () => {
        this.$route;
        return {};
      },
      () => {
        this.checkIsPDCA();
      }
    );
  },
  async mounted() {
    this.checkIsPDCA();
    EventBus.$on("startLoading", () => (this.loading = true));
    EventBus.$on("stopLoading", () => (this.loading = false));
    EventBus.$on("triggerDefaultMethod", () => this.triggerDefaultMethod());
    EventBus.$on("showSnackbar", (text, color) => {
      this.text = text;
      this.snackbarColor = color;
      this.snackbar = true;
    });
    this.triggerDefaultMethod();
  },
};
</script>

<style>
* {
  font-family: "Poppins", "Roboto", sans-serif;
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

.comment-position {
  position: fixed !important;
  right: 3rem !important;
  width: 12rem !important;
}

.disable-scrolling {
  height: 100vh !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  overflow: hidden !important;
}

.main-height {
  height: 100vh;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.item-border {
  border: 5px solid #eb5353;
  border-radius: 10px;
}

.hide {
  display: none !important;
}
</style>
