<template>
  <v-app class="py-16 px-6">
    <v-data-table
      :headers="headers"
      :items="data"
      sort-by="name"
      class="mx-16 mt-6"
    >
      <template v-slot:top>
        <v-row class="mb-6 mx-0" justify="end">
          <v-dialog v-model="dialog" max-width="555px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary radius"
                height="42"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2" size="18">mdi-plus</v-icon>
                Add Data
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5 pl-3 pt-6 span-class">{{
                  formTitle
                }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <h2 class="py-4">Name</h2>
                      <v-text-field
                        v-model="form.vcname"
                        label="Type Data Name"
                        solo
                        clearable
                        flat
                        dense
                        background-color="#EFEFEF"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <h2 class="pb-4 mt-n4">Code</h2>
                      <v-text-field
                        v-model="form.vccode"
                        label="Type Data Code"
                        solo
                        clearable
                        flat
                        dense
                        background-color="#EFEFEF"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary mb-8 mr-5" width="30%" @click="addData">
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px"
            >\
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="start">
          <v-btn
            class="ms-7 px-3 green accent-4 radius weight-400"
            min-width="40"
            min-height="40"
          >
            <v-icon color="white" size="20">mdi-magnify-plus-outline</v-icon>
            <p class="button-p mb-0 ms-2">Update</p>
          </v-btn>
          <v-btn
            class="mx-2 px-6 blue accent-4 radius weight-400"
            @click="editItem(item)"
            min-width="40"
            min-height="40"
          >
            <v-icon color="white" size="20">mdi-pencil </v-icon>
            <p class="button-p mb-0 ms-2">Edit</p>
          </v-btn>
          <v-btn
            class="px-3 red radius weight-400"
            @click="deleteItem(item)"
            min-width="40"
            min-height="40"
          >
            <v-icon color="white" size="20">mdi-delete</v-icon>
            <p class="button-p mb-0 ms-2">Delete</p>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  name: "DataTower",

  data: () => ({
    dialog: false,
    dialogDelete: false,
    data: [],
    result: null,
    isEditing: null,
    headers: [
      {
        text: "No",
        sortable: false,
        value: "id",
      },

      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Code",
        value: "code",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],

    form: {
      id: 0,
      vcname: "",
      vccode: "",
    },
  }),

  computed: {
    formTitle() {
      if (this.isEditing) {
        return "Editing item";
      } else {
        return "Adding Item";
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.loadData();

    if (this.isEditing) {
      console.log("editing");
    } else {
      console.log("adding");
    }
  },
  methods: {
    async loadData() {
      try {
        const res = await axios.get(
          "https://be-kievit.smkrus.com/api/data-master/tower"
        );
        const data = res.data.data;
        this.result = res.data.message;
        data.forEach((e) => {
          this.data.push({
            id: e.id,
            name: e.vcname,
            code: e.vccode,
          });
        });
        // res.forEach((e) => {});
      } catch (err) {
        console.log(err);
      }
    },

    async addData() {
      try {
        await axios.post(
          `${this.$api}/data-master/tower?vccode=${this.form.vccode}&vcname=${this.form.vcname}`
        );
        this.form.vccode = "";
        this.form.vcname = "";
      } catch {
        console.log("ra iso");
      }
    },

    async editData() {
      try {
        await axios.put(
          `${this.$api}/data-master/tower?vccode=${this.form.vccode}&vcname=${this.form.vcname}`
        );
        await this.load();
        this.form = {};
        this.isEditing = false;
      } catch (err) {
        console.log(err);
      }
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style scoped>
.weight-400 {
  font-weight: 400 !important;
}

.radius {
  border-radius: 0.6rem !important;
}

.button-p {
  color: white;
  text-align: center !important;
}

h4,
h2 {
  font-weight: 500;
}

h2 {
  color: black;
}

.span-class {
  font-weight: 600 !important;
}
</style>

<style lang="scss" scoped>
::v-deep .v-application .text-center {
  text-align: start !important;
}

::v-deep .v-data-table__wrapper {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
::v-deep th {
  color: white !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-align: start !important;
}

::v-deep th:first-child {
  border-radius: 10px 0px 0px 0px;
  width: 5% !important;
}

::v-deep th:last-child {
  border-radius: 0px 10px 0px 0px;
  width: 30% !important;
}

::v-deep th {
  background-color: #eb5353 !important;
}

::v-deep td {
  border: 0px solid black !important;
}

::v-deep td:last-child {
  padding: 1.8rem 0 1.8rem 0 !important;
}

::v-deep tr:last-child td {
  border-bottom: 0px solid black !important;
}

::v-deep tr:last-child td:first-child,
tr:last-child td:first-child:hover {
  border-radius: 0 0 0 10px !important;
}

::v-deep tr:last-child td:last-child,
tr:last-child td:last-child:hover {
  border-radius: 0 0 10px 0 !important;
}
</style>
