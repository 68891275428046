import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Login from "../views/Login.vue";
// import ChartMenu from "../views/chart/ChartMenu.vue";
import AllChart from "../views/chart/AllChart.vue";
import Speedometer from "../views/chart/ChartGauge.vue";
import ChartData from "../views/chart/ChartDataView.vue";
import HomeMenu from "../views/home/HomeMenu.vue";
import LayoutContent from "../layouts/Content.vue";
import auth from "../middlewares/auth";
import notAuth from "../middlewares/not-auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/speedometer",
    component: Speedometer,
    meta: {
      middleware: auth,
    },
  },

  {
    path: "/",
    component: Home,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "/",
        name: "HomeMenu",
        component: HomeMenu,
        meta: {
          middleware: auth,
        },
      },
      {
        path: "/chart/:menu",
        name: "ChartMenu",
        component: HomeMenu,
        meta: {
          middleware: auth,
        },
      },
      {
        path: "/chart/:menu/:subMenu",
        name: "ChartSubMenu",
        component: AllChart,
        meta: {
          middleware: auth,
        },
      },
      {
        path: "/chart/:menu/:subMenu/:data",
        name: "ChartData",
        component: ChartData,
        meta: {
          middleware: auth,
        },
      },
    ],
  },
  {
    path: "/menu-admin",
    component: LayoutContent,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "/menu-admin/:menu/:subMenu",
        name: "LayoutContent",
        component: LayoutContent,
        meta: {
          middleware: auth,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: notAuth,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({
      ...context,
      next: nextMiddleware,
    });
  }
  return next();
});

export default router;
