<template>
  <v-row class="table-body no-overflow my-5">
    <v-col v-if="pdca" :cols="hasFilterProp ? '12' : '10'">
      <div
        class="d-flex flex-row align-center justify-center primary--text font-weight-medium mt-3"
      >
        <p>{{ subtitle }}</p>
      </div>
      <div class="border">
        <v-row>
          <v-col cols="12">
            <div class="primary white--text text-center py-2">Issue & RCA</div>
            <div class="pa-2">{{ result.issue_rca }}</div>
          </v-col>
          <v-col cols="12">
            <div class="primary white--text text-center py-2">
              Possible caused
            </div>
            <div class="pa-2">{{ result.possible_cause }}</div>
          </v-col>
          <v-col cols="12">
            <div class="primary white--text text-center py-2">Plan</div>
            <div class="pa-2">{{ result.plan }}</div>
          </v-col>
          <v-col cols="3" class="right-border">
            <div class="primary white--text text-center py-2">Do</div>
            <div v-for="(item, index) in result.do" :key="index">
              <div class="px-2">{{ index + 1 }}. {{ item.value }}</div>
            </div>
          </v-col>
          <v-col cols="3" class="right-border">
            <div class="primary white--text text-center py-2 mb-2 right-border">
              PIC
            </div>
            <center>
              <div v-for="(item, index) in result.do" :key="index">
                <div class="px-2">{{ item.pic }}</div>
              </div>
            </center>
          </v-col>
          <v-col cols="3" class="right-border">
            <div class="primary white--text text-center py-2 mb-2">
              Due date
            </div>
            <center>
              <div v-for="(item, index) in result.do" :key="index">
                <div class="px-2">{{ item.due_date }}</div>
              </div>
            </center>
          </v-col>
          <v-col cols="3">
            <div class="primary white--text text-center py-2 mb-2">Status</div>
            <center>
              <div v-for="(item, index) in result.do" :key="index">
                <div class="px-2">{{ item.status }}</div>
              </div>
            </center>
          </v-col>
          <v-col cols="12">
            <div class="primary white--text text-center py-2">Check</div>
            <div class="pa-2">{{ result.check }}</div>
          </v-col>
          <v-col cols="3" class="right-border">
            <div class="primary white--text text-center py-2 mb-2">
              Action (prevention)
            </div>
            <div v-for="(item, index) in result.action" :key="index">
              <div class="px-2">{{ index + 1 }}. {{ item.value }}</div>
            </div>
          </v-col>
          <v-col cols="3" class="right-border">
            <div class="primary white--text text-center py-2 mb-2">PIC</div>
            <center>
              <div v-for="(item, index) in result.action" :key="index">
                <div class="px-2">{{ item.pic }}</div>
              </div>
            </center>
          </v-col>
          <v-col cols="3" class="right-border">
            <div class="primary white--text text-center py-2 mb-2">
              Due date
            </div>
            <center>
              <div v-for="(item, index) in result.action" :key="index">
                <div class="px-2">{{ item.due_date }}</div>
              </div>
            </center>
          </v-col>
          <v-col cols="3">
            <div class="primary white--text text-center py-2 mb-2">Status</div>
            <center>
              <div v-for="(item, index) in result.action" :key="index">
                <div class="px-2">{{ item.status }}</div>
              </div>
            </center>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col cols="10" v-if="backLog">
      <div
        v-if="route.data != 'backlog-week'"
        class="d-flex flex-row align-center justify-center primary--text font-weight-medium mt-3"
      >
        <p class="mb-0">{{ subtitle }}</p>
      </div>
      <p
        class="text-center font-weight-bold primary--text mt-10"
        v-if="route.data == 'backlog-week'"
      >
        Table Backlog Week
      </p>

      <v-data-table
        :headers="headers"
        :items="LogData"
        :items-per-page="5"
        class="mx-16 mt-6"
        :footer-props="{
          showFirstLastPage: true,
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          firstIcon: 'mdi-chevron-double-left',
          lastIcon: 'mdi-chevron-double-right',
        }"
      >
        <template v-slot:top> </template>
      </v-data-table>
    </v-col>

    <v-col
      v-if="route.data != 'backlog-week' && !hasFilterProp"
      cols="2"
      align-self="start"
      class="px-5 sticky-top"
    >
      <v-btn
        @click.stop="drawer = !drawer"
        color="primary"
        class="text-none ml-3"
        style="border-radius: 10px"
        elevation="0"
        >Comment<v-icon right dark> mdi-comment </v-icon></v-btn
      >
      <div v-if="year" class="mt-5 ml-3">Year:</div>
      <v-select
        v-if="year"
        :items="years"
        class="ml-3"
        v-model="selectedYear"
        label="Year"
        solo
        @change="changeData(true, true)"
        background-color="#F6F6FB"
        flat
        dense
      ></v-select>

      <div v-if="titleVisible" class="ml-3">Title:</div>
      <v-select
        v-if="titleVisible"
        class="ml-3"
        :items="titles"
        v-model="selectedTitle"
        label="Title"
        solo
        item-text="vcjudul"
        @change="changeData(true, false)"
        background-color="#F6F6FB"
        flat
        dense
      ></v-select>
      <div class="ml-3" v-if="pic">PIC:</div>
      <v-select
        v-if="pic"
        :items="pics"
        item-text="vcname"
        item-value="vcname"
        v-model="selectedPic"
        label="PIC"
        class="ml-3"
        solo
        @change="changeData()"
        background-color="#F6F6FB"
        flat
        dense
      ></v-select>
      <div v-if="log" class="ml-3">Status BackLog:</div>
      <v-switch
        class="ml-6"
        v-model="selectedLog"
        @change="changeData()"
        true-value="1"
        false-value="0"
        v-if="log"
        hide-details
        inset
      ></v-switch>
    </v-col>

    <v-navigation-drawer width="344" v-model="drawer" right absolute temporary>
      <v-list-item>
        <v-list-item-content>
          <div class="d-flex flex-row">
            <v-list-item-title class="weight-600"
              >Comment Rooms</v-list-item-title
            >

            <v-btn icon @click="closeDrawer">
              <v-icon> mdi-window-close </v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list dense v-if="route.data != 'backlog-week'">
        <v-list-item-content>
          <div
            class="d-flex flex-row"
            v-for="(comment, i) in userComments"
            :key="i"
          >
            <img src="@/assets/user.png" class="smol-img ml-4" />
            <div class="d-flex flex-column ml-4">
              <p class="mb-2 weight-600">{{ comment.userName }}</p>
              <p>{{ comment.createdAt }}</p>
              <p class="">{{ comment.comments }}</p>
            </div>
          </div>
        </v-list-item-content>
      </v-list>

      <template v-slot:append v-if="route.data != 'backlog-week'">
        <div class="text-center">
          <v-pagination
            @input="onChangePagination"
            :total-visible="5"
            v-model="paginationCounter"
            :length="paginationLength"
          ></v-pagination>
        </div>

        <div class="pa-2">
          <v-text-field
            v-model="form.txtComment"
            @click:append="add"
            append-icon="mdi-send"
            label="Type Your Comment"
            outlined
          >
          </v-text-field>
        </div>
      </template>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import { EventBus } from "../../event-bus";
import axios from "axios";

export default {
  name: "TableView",
  props: [
    "resData",
    "route",
    "hasFilterProp",
    "titleProp",
    "yearProp",
    "picProp",
  ],
  data: () => ({
    userComments: [],
    form: {
      txtComment: "",
    },
    isActive: false,
    drawer: null,
    paginationCounter: 1,
    paginationLength: 1,
    selectedYear: 0,
    selectedTitle: "",
    titles: [],
    title: "",
    subtitle: "",
    result: {},
    pdca: true,
    backLog: false,
    selectedLog: "0",
    LogStatus: [],
    selectedPic: 1,
    pics: [],
    selectedLogYear: 2022,
    years: [],
    LogData: [],
    headers: [],
    chartData: [],
    year: false,
    pic: false,
    titleVisible: false,
    log: false,
    apiRequestTimer: null,
  }),
  watch: {
    resData: async function (newVal, oldVal) {
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      await this.initData();
    },
  },
  created() {
    var d = new Date();
    this.selectedYear = d.getFullYear();
    this.$watch(
      () => {
        this.$route;
        return {};
      },
      async () => {
        this.getDefaultData();
      }
    );
  },
  mounted() {
    this.apiRequestTimer = setTimeout(() => {
      this.getDefaultData();
    }, 1000);
  },
  beforeDestroy() {
    clearTimeout(this.apiRequestTimer);
  },
  methods: {
    async getDefaultData() {
      if (this.titleProp) {
        this.selectedTitle = this.titleProp;
      }
      if (this.yearProp) {
        this.selectedYear = this.yearProp;
      }
      if (this.picProp) {
        this.selectedPic = this.picProp;
      }
      await this.setChartParameters();
      EventBus.$emit("startLoading");
      this.setTitle();
      switch (this.route.data) {
        case "pdca-improvement":
          this.pdca = true;
          this.backLog = false;
          break;
        case "backlog-month":
        case "backlog-week":
          this.backLog = true;
          this.pdca = false;
          break;
      }

      for (var i = 2014; i <= 2023; i++) {
        this.years.push(i);
      }
      for (var j = 0; j <= 1; j++) {
        this.LogStatus.push(j);
      }
      this.years.reverse();
      await this.initData(false);
      if (!this.hasFilterProp) {
        await this.loadComment();
      }
      await this.getListZones();
      EventBus.$emit("stopLoading");
    },
    setTitle() {
      var route = this.route;
      var localStorageData = JSON.parse(localStorage.getItem("data"));
      localStorageData = localStorageData["data"][1]["menu-content"].find(
        (e) => e.vcurl == `/chart/${route.menu}`
      );
      localStorageData = localStorageData["childrens"].find(
        (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}`
      );

      this.title = localStorageData.vcname;
      EventBus.$emit("changeTitle", this.title);

      localStorageData = localStorageData["childrens"].find(
        (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}/${route.data}`
      );

      this.subtitle = localStorageData.vcname;
    },
    async getListZones() {
      this.pics = JSON.parse(localStorage.getItem("pics"));
    },
    async setChartParameters() {
      var route = this.route;
      var data = route.data;

      this.titleVisible = true;
      this.year = true;
      this.log = false;
      this.pic = false;
      switch (data) {
        case "backlog-month":
          this.log = true;
          this.pic = true;
          this.year = true;
          this.titleVisible = false;
          this.selectedPic = 0;
          EventBus.$emit("startLoading");
          await this.getListZones();
          EventBus.$emit("stopLoading");
          break;
      }
    },
    async changeData(isFromSelect, isFromYear) {
      EventBus.$emit("startLoading");
      await this.initData(isFromSelect, isFromYear);
      EventBus.$emit("stopLoading");
    },

    async initData(isFromSelect, isFromYear) {
      this.LogData = [];
      this.headers = [];
      
      switch (this.route.data) {
        case "pdca-improvement":
          if (!isFromSelect || isFromYear) {
            await this.getTitles();
          }
          try {
            const res = await axios.get(
              `${this.$api}/chart/asset-management/improvement-asset/pdca-improvement`,
              {
                params: {
                  year: this.selectedYear,
                  title: this.selectedTitle,
                },
              }
            );
            if (res.status == 200) {
              this.result = res.data.data;
            }
            // else {
            //   EventBus.$emit("showSnackbar", res.data, "red");
            // }
          } catch (e) {
            EventBus.$emit("showSnackbar", e, "red");
          }
          break;
        case "backlog-month":
        case "backlog-week":
          EventBus.$emit("startLoading");
          try {
            var route = this.route;
            this.setTitle();
            var res = this.resData;
            if (route.data != "backlog-week") {
              const params = {
                year: this.selectedYear,
                pic: this.selectedPic == 'All PIC' ? undefined : this.selectedPic,
                backlog: this.selectedLog,
              };
              res = await axios.get(
                `${this.$api}/chart/${route.menu}/${route.subMenu}/${route.data}`,
                { params }
              );
            }
            console.log("res data", res);
            var headers = res.data.data[0]["table_head"];
            var data = res.data.data[0]["table_body"];
            if (route.data == "backlog-week") {
              headers = res.data.data[1]["detail"][0]["table_head"];
              data = res.data.data[1]["detail"][0]["table_body"];
            }
            if (headers.length > 0) {
              var j = 0;
              for (var i in data[0]) {
                console.log("header", i);
                this.headers.push({
                  text: headers[j],
                  value: i,
                  sortable: false,
                  align: "start",
                });
                j++;
              }
            }

            // if (headers.length > 0) {
            //   headers.forEach((head) => {
            //     this.headers.push({
            //       text: head,
            //       value: headers,
            //       sortable: head == "date" ? false : true,
            //       align: head == "id" ? "start" : "center",
            //     });
            //   });
            // }
            this.LogData = data;
            console.log("log data", this.LogData);
          } catch (err) {
            this.chartData = [];
            this.LogData = [];
            var error = err;
            console.log(err);
            if (err.response.data.message) {
              error = err.response.data.message;
            }
            EventBus.$emit("showSnackbar", error, "red");
          }
          EventBus.$emit("stopLoading");
          break;
      }
    },

    async getTitles() {
      try {
        const res = JSON.parse(localStorage.getItem("titles"));
        if (res.status == 200) {
          this.titles = res.data.data;
          if (this.titles.length > 0) {
            // only retrieve titles with current year
            var tempTitles = [];
            tempTitles.push(...this.titles);

            if (this.selectedYear === 0) {
              var years =
                tempTitles[tempTitles.length - 1]["vcjudul"].split(" ");
              var year = years[years.length - 1];
              this.selectedYear = year;
            }

            for (var i = 0; i < tempTitles.length; i++) {
              var data = tempTitles[i];
              try {
                var words = data["vcjudul"].split(" ");
                var word = words[words.length - 1];
                if (this.years.indexOf(word) === -1) {
                  this.years.push(word);
                }
                if (word != `${this.selectedYear}`) {
                  const isSameTitle = (element) =>
                    element["vcjudul"] == data["vcjudul"];
                  var index = this.titles.findIndex(isSameTitle);
                  console.log("index " + index);
                  this.titles.splice(index, 1);
                }
              } catch (_) {
                console.log(data);
                console.log("error in catch: " + _);
              }
            }
            this.selectedTitle = this.titles[this.titles.length - 1]["vcjudul"];
            var title = this.selectedTitle.split(" ");
            this.year = title[words.length - 1];
          }
          console.log("titles", this.titles);
        } else {
          EventBus.$emit("showSnackbar", res.data, "red");
        }
      } catch (e) {
        EventBus.$emit("showSnackbar", e, "red");
      }
    },

    async loadComment() {
      try {
        const response = await axios.get(`${this.$api}/chart/comments/7`);
        const data = response.data.data.comments;
        const length_data = response.data.data.last_page;
        this.paginationLength = length_data;
        data.forEach((comment) => {
          this.userComments.push({
            comments: comment.comment,
            userName: comment.user,
            createdAt: comment.created_at,
          });
        });
      } catch (err) {
        console.log(err);
      }
    },

    async add() {
      try {
        await axios.post(
          `${this.$api}/chart/comments/7?txtcomment=${this.form.txtComment}`
        );
        this.form.txtComment = "";
        this.onChangePagination(this.paginationLength);
      } catch (err) {
        console.log(err);
      }
    },

    async onChangePagination(number) {
      if (number) {
        try {
          const response = await axios.get(
            `${this.$api}/chart/comments/7?page=${number}`
          );
          this.userComments.length = 0;
          const data = response.data.data.comments;

          data.forEach((comment) => {
            this.userComments.push({
              comments: comment.comment,
              userName: comment.user,
              createdAt: comment.created_at,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
.no-overflow {
  overflow-y: scroll !important;
}
.sticky-top {
  position: sticky;
  top: 0;
}
.table-body {
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
}
.border {
  border: 1px solid gray;
  padding: 12px;
}

.right-border {
  border-right: 2px solid gray;
}
</style>

<style lang="scss" scoped>
::v-deep .col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding: 0px;
}
</style>

<style scoped>
rect[stroke-opacity] {
  stroke-width: 0 !important;
}
.status {
  border-radius: 4px;
  width: min-content;
  padding: 4px 8px;
}

.Controlable {
  background: #e5e7eb;
  border: 1px solid #9ca3af;
}

.Monitoring {
  background: #fef9c3;
  border: 1px solid #f59e0b;
}

.shadow {
  box-shadow: 0px 4px 15px rgba(138, 138, 138, 0.1);
}

.smol-img {
  height: 3rem;
  width: 2.8rem;
}

.weight-600 {
  font-weight: 600;
}
</style>

<style scoped>
.sticky-bar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
}

.v-text-field-khusus {
  width: 250px !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.radius {
  border-radius: 0.6rem !important;
}

.button-p {
  color: white;
  text-align: center !important;
}

h4,
h2 {
  font-weight: 500;
}

h2 {
  color: black;
}

.span-class {
  font-weight: 600 !important;
}

table {
  border-collapse: collapse !important;
  width: 98% !important;
  margin: auto !important;
}

th {
  background: #eb5353 !important;
  color: white !important;
  padding: 1rem !important;
}

td,
th {
  border: 1px solid #000000 !important;
  width: 33% !important;
  font-weight: 500;
  font-size: 20px;
}

td {
  margin: auto !important;
}

tr:nth-child(even) {
  background-color: #fff !important;
}

.col {
  /* width: 100%; */
  padding: 0px !important;
}

.parameter {
  font-weight: 600;
  font-size: 20px;
}

.type {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
}
</style>

<style lang="scss" scoped>
::v-deep th {
  background-color: #eb5353 !important;
  color: white !important;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
</style>

<style>
.line {
  height: 2px;
  background: #efefef;
  border-radius: 10px;
  width: 98%;
  margin: auto;
}

.v-text-field {
  padding: 0.7rem 0.5rem 0 0.5rem !important;
}

.v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
