import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import EventBus from "./event-bus";
import VueGlobalVar from "vue-global-var";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import "./styles.sass";
import DatetimePicker from "vuetify-datetime-picker";

Vue.use(Chartkick.use(Chart));
Vue.use(DatetimePicker);

Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;

Vue.use(VueGlobalVar, {
  globals: {
    $api: "https://kievit-api.rplrus.com/api",
    $storage: "https://kievit-api.rplrus.com/storage",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
