<template>
  <v-app-bar flat height="80" class="px-10" color="white">
    <v-row justify="space-between" style="height: 100%" align="center">
      <v-img
        src="../assets/kievit.png"
        max-height="120"
        max-width="120"
      ></v-img>
      <div
        v-if="title"
        class="ml-13 title-box primary white--text title-container py-3 px-10"
      >
        {{ title }}
      </div>
      <div>
        <button @click="openDialog">
          <v-row align="center">
            <v-avatar><v-img src="../assets/admin-logo.svg"></v-img></v-avatar>

            <div class="mx-5 font-weight-medium" v-if="data != ''">
              Hai, {{ data.data_user.username }}!
            </div>
          </v-row>
        </button>
      </div>
    </v-row>

    <v-dialog v-model="dialogLogOut" width="400" :retain-focus="false">
      <v-card>
        <v-card-title class="text-h5"> Logout </v-card-title>

        <v-card-text> Are you sure to Logout </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mx-5"
            text
            @click="dialogLogOut = false"
          >
            No
          </v-btn>
          <v-btn color="primary" text @click="onLogOut"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { EventBus } from "../event-bus";

export default {
  name: "Navbar",
  data: () => ({
    data: "",
    title: "",
    dialogLogOut: false,
  }),
  mounted() {
    EventBus.$on("changeTitle", (title) => (this.title = title));
    this.data = JSON.parse(localStorage.getItem("data"));
  },

  methods: {
    openDialog() {
      this.dialogLogOut = true;
    },
    onLogOut() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.title-container {
  border-radius: 10px;
}
</style>
