<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-img
          id="image"
          max-width="500"
          max-height="500"
          src="../../assets/kievit.png"
        ></v-img>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeMenu",
};
</script>

<style scoped>
#image {
  opacity: 0.5;
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
</style>
