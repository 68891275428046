<template>
  <v-app class="py-16 px-6">
    <div>
      <v-data-table :headers="headers" :items="data" class="mx-16 mt-6">
        <template v-slot:top>
          <v-row class="mb-6 mx-0" justify="end">
            <v-dialog v-model="dialog" width="1050px" :retain-focus="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary radius"
                  height="42"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog()"
                >
                  <v-icon class="mr-2" size="18">mdi-plus</v-icon>
                  Add Data
                </v-btn>
              </template>

              <v-card>
                <v-form ref="form" v-on:submit.prevent>
                  <v-card-title
                    class="d-flex flex-row justify-space-between align-center sticky-bar"
                  >
                    <span class="text-h5 pl-3 pt-6 span-class">
                      {{ isEditing ? "Edit Data" : "Add Data" }} {{ formTitle }}
                    </span>

                    <v-btn
                      color="primary mt-7"
                      width="30%"
                      @click="isEditing ? submitData(true) : submitData(false)"
                    >
                      {{ isEditing ? "Edit Data" : "Add Data" }}
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          v-for="(model, i) in form"
                          :key="i"
                        >
                          <div class="d-flex flex-column">
                            <h2
                              class="py-4 ps-2 text-left"
                              v-if="
                                model.label !== 'Check' &&
                                model.label !== 'Plan'
                              "
                            >
                              {{ model.label }}
                            </h2>

                            <v-text-field
                              v-if="
                                model.type !== 'select' &&
                                model.type !== 'datetime' &&
                                model.type !== 'date' &&
                                model.label !== 'Check' &&
                                model.label !== 'Plan'
                              "
                              class="flex-grow-1 p-0"
                              v-model="models[model.name]"
                              :type="
                                model.type == 'integer' ? 'number' : model.type
                              "
                              :rules="validateRules(model)"
                              :label="'Input the ' + model.type"
                              solo
                              clearable
                              flat
                              dense
                              background-color="#EFEFEF"
                            ></v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          v-for="(model, i) in form"
                          :key="i"
                          class="p-0"
                        >
                          <h2
                            class="py-4 ps-2 text-left"
                            v-if="
                              model.label != 'Year' &&
                              model.label != 'Check' &&
                              model.label != 'Title' &&
                              model.label != 'Issue & RCA' &&
                              model.label != 'Possible Cause'
                            "
                          >
                            {{ model.label }}
                          </h2>

                          <v-text-field
                            v-if="
                              model.label != 'Year' &&
                              model.label != 'Check' &&
                              model.label != 'Title' &&
                              model.label != 'Issue & RCA' &&
                              model.label != 'Possible Cause'
                            "
                            class="v-text-field-plan"
                            v-model="models[model.name]"
                            :type="
                              model.type == 'integer' ? 'number' : model.type
                            "
                            :rules="validateRules(model)"
                            :label="'Input the ' + model.type"
                            solo
                            clearable
                            flat
                            dense
                            background-color="#EFEFEF"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" v-for="(form, i) in subForms" :key="i">
                          <v-text-field
                            v-if="!checkIfHasChild(form.label)"
                            :v-model="subFormsModels[form.model]"
                            :label="form.label"
                          ></v-text-field>

                          <div v-else-if="form.label == 'Do'">
                            <div
                              class="d-flex flex-row justify-space-between ps-2"
                            >
                              <h2>{{ form.label }}</h2>
                              <v-btn
                                color="primary radius mb-4"
                                height="37"
                                width="107"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  form.label == 'Do'
                                    ? addDoForm(true)
                                    : console.log('do')
                                "
                              >
                                Add

                                <v-icon
                                  class="ms-2 align-center"
                                  size="16
                                  "
                                  >mdi-plus</v-icon
                                >
                              </v-btn>
                            </div>
                            <div
                              v-for="(childField, index) in form.children"
                              :key="index"
                              :class="index == 0 ? 'my-0' : 'my-3'"
                            >
                              <div>
                                <table>
                                  <tr>
                                    <th
                                      v-for="child in childField"
                                      :key="child.model"
                                    >
                                      <h4 class="py-3 text-center white--text">
                                        {{ child.label }}
                                      </h4>
                                    </th>
                                    <th>
                                      <h4>Action</h4>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      v-for="child in childField"
                                      :key="child.model"
                                    >
                                      <div v-if="form.label == 'Do'">
                                        <v-text-field
                                          v-if="child.type == 'text'"
                                          v-model="
                                            subFormsModels['do'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          clearable
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :type="child.type"
                                          :label="child.label"
                                          class="px-3 mt-5 v-text-field-ayolah"
                                        ></v-text-field>

                                        <v-text-field
                                          v-if="child.type == 'date'"
                                          v-model="
                                            subFormsModels['do'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          clearable
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :type="child.type"
                                          :label="child.label"
                                          class="px-3 mt-5"
                                        ></v-text-field>

                                        <v-select
                                          v-if="child.label == 'Pic'"
                                          v-model="
                                            subFormsModels['do'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          filled
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :items="listUsers"
                                          item-text="username"
                                          item-value="id"
                                          class="px-3 pb-0 mt-5 v-text-field-select"
                                          return-object
                                        ></v-select>
                                        <v-select
                                          v-if="child.label == 'Status'"
                                          v-model="
                                            subFormsModels['do'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          filled
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :items="listStatus"
                                          @change="testChange()"
                                          item-text="status"
                                          item-value="id"
                                          return-object
                                          class="px-3 pb-0 mt-5 v-text-field-select"
                                        ></v-select>
                                      </div>
                                    </td>
                                    <td class="px-7">
                                      <v-btn
                                        width="95"
                                        color="primary radius"
                                        @click="
                                          form.label == 'Do'
                                            ? deleteDoForm(
                                                childField[0].id,
                                                index
                                              )
                                            : deleteActionForm(
                                                childField[0].id,
                                                index
                                              )
                                        "
                                        class="elevation-0 px-3"
                                        flat
                                        >Delete

                                        <v-icon
                                          small
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="openDialogDelete(item.id)"
                                          class="ms-2"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" v-for="(model, i) in form" :key="i">
                          <h2
                            class="ps-2 text-left pt-6"
                            v-if="model.label == 'Check'"
                          >
                            {{ model.label }}
                          </h2>

                          <v-text-field
                            v-if="model.label == 'Check'"
                            class="v-text-field-plan pb-4"
                            v-model="models[model.name]"
                            :type="
                              model.type == 'integer' ? 'number' : model.type
                            "
                            :rules="validateRules(model)"
                            :label="'Input the ' + model.type"
                            solo
                            clearable
                            flat
                            dense
                            background-color="#EFEFEF"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" v-for="(form, i) in subForms" :key="i">
                          <v-text-field
                            v-if="!checkIfHasChild(form.label)"
                            :v-model="subFormsModels[form.model]"
                            :label="form.label"
                          ></v-text-field>

                          <div v-else-if="form.label == 'Action'">
                            <div
                              class="d-flex flex-row justify-space-between ps-2"
                            >
                              <h2>{{ form.label }}</h2>
                              <v-btn
                                color="primary radius mb-4"
                                height="37"
                                width="107"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  form.label == 'Action'
                                    ? addActionForm(true)
                                    : console.log('Action')
                                "
                              >
                                Add

                                <v-icon
                                  class="ms-2 align-center"
                                  size="16
                                  "
                                  >mdi-plus</v-icon
                                >
                              </v-btn>
                            </div>
                            <div
                              v-for="(childField, index) in form.children"
                              :key="index"
                              :class="index == 0 ? 'my-0' : 'my-3'"
                            >
                              <div>
                                <table>
                                  <tr>
                                    <th
                                      v-for="child in childField"
                                      :key="child.model"
                                    >
                                      <h4 class="py-3 text-center white--text">
                                        {{ child.label }}
                                      </h4>
                                    </th>
                                    <th>
                                      <h4>Action</h4>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      v-for="child in childField"
                                      :key="child.model"
                                    >
                                      <div v-if="form.label == 'Action'">
                                        <v-text-field
                                          v-if="child.type == 'text'"
                                          v-model="
                                            subFormsModels['action'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          clearable
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :type="child.type"
                                          :label="child.label"
                                          class="px-3 mt-5 v-text-field-ayolah"
                                        ></v-text-field>

                                        <v-text-field
                                          v-if="child.type == 'date'"
                                          v-model="
                                            subFormsModels['action'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          clearable
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :type="child.type"
                                          :label="child.label"
                                          class="px-3 mt-5"
                                        ></v-text-field>

                                        <v-select
                                          v-if="child.label == 'Pic'"
                                          v-model="
                                            subFormsModels['action'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          filled
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :items="listUsers"
                                          item-text="username"
                                          item-value="id"
                                          class="px-3 pb-0 mt-5 v-text-field-select"
                                          return-object
                                        ></v-select>
                                        <v-select
                                          v-if="child.label == 'Status'"
                                          v-model="
                                            subFormsModels['action'][index][
                                              child.model
                                            ]
                                          "
                                          solo
                                          filled
                                          flat
                                          dense
                                          background-color="#EFEFEF"
                                          :items="listStatus"
                                          @change="testChange()"
                                          item-text="status"
                                          item-value="id"
                                          return-object
                                          class="px-3 pb-0 mt-5 v-text-field-select"
                                        ></v-select>
                                      </div>
                                    </td>
                                    <td class="px-7">
                                      <v-btn
                                        width="95"
                                        color="primary radius"
                                        @click="
                                          form.label == 'Action'
                                            ? deleteActionForm(
                                                childField[0].id,
                                                index
                                              )
                                            : console.log('Action')
                                        "
                                        class="elevation-0 px-3"
                                        flat
                                        >Delete

                                        <v-icon
                                          small
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="openDialogDelete(item.id)"
                                          class="ms-2"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="openDialog(item.id)">
            mdi-pencil
          </v-icon>
          <v-dialog v-model="dialogDelete" width="400" :retain-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                @click="openDialogDelete(item.id)"
              >
                mdi-delete
              </v-icon>
            </template>
            <v-card>
              <v-card-title class="text-h5"> Delete Data </v-card-title>

              <v-card-text> Are you sure to delete this data? </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="mx-5"
                  text
                  @click="dialogDelete = false"
                >
                  No
                </v-btn>
                <v-btn color="primary" text @click="deleteData(item.id)">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { EventBus } from "../../event-bus";

export default {
  name: "DataTower",

  data: () => ({
    dialog: false,
    dialogDelete: false,
    data: [],
    actualData: [],
    listStatus: [],
    listUsers: [],
    routing: "",
    textFieldProps: {
      width: "100px",
    },
    result: null,
    isEditing: false,
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    headers: [],
    models: {},
    form: [],
    dummySelect: [],
    subFormsModels: {
      do: [],
      action: [],
    },
    doIndex: 0,
    actionIndex: 0,
    subForms: [
      {
        label: "Do",
        required: true,
        children: [],
      },
      {
        label: "Action",
        required: true,
        children: [],
      },
    ],
  }),

  created() {
    this.$watch(
      () => {
        this.$route;
        return {};
      },
      async () => {
        await this.initData();
      }
    );
  },

  computed: {
    formTitle() {
      return this.humanize(this.$route.params.subMenu);
    },
  },

  async mounted() {
    await this.initData();
  },
  methods: {
    humanize(str) {
      var i,
        frags = str.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    testChange() {
      console.log(this.subFormsModels);
    },
    clearData() {
      this.data = [];
      this.actualData = [];
      this.listTower = [];
      this.listCategories = [];
      this.listTypes = [];
      this.listZones = [];
      this.form = [];
      this.models = {};
      this.headers = [];
    },
    async initData() {
      EventBus.$emit("startLoading");
      this.clearData();
      this.clearForm();

      await this.loadData();
      await this.loadFormProperties();
      EventBus.$emit("stopLoading");
    },

    async getListUsers() {
      try {
        const res = await axios.get(`${this.$api}/list/users`);
        const data = res.data.data;
        this.listUsers = data;
        console.log("list users", this.listUsers);
      } catch (error) {
        console.log(error);
      }
    },

    async getListStatus() {
      this.listStatus = [
        {
          id: 1,
          status: "Done",
        },
        {
          id: 2,
          status: "Pending",
        },
        {
          id: 3,
          status: "Blocked",
        },
      ];
      // try {
      //   const res = await axios.get(`${this.$api}/list/users`);
      //   const data = res.data.data;
      //   this.listUsers = data;
      //   console.log('list users', this.listUsers);
      // } catch (error) {
      //   console.log(error);
    },

    async loadFormProperties() {
      this.clearForm();
      try {
        const route = this.$route.params;
        const res = await axios.get(
          `${this.$api}/${route.menu}/${route.subMenu}/create`
        );
        const properties = res.data.base;
        this.form = properties;
        console.log("form", this.form);
      } catch (error) {
        console.log(error);
      }
    },

    clearModels() {
      this.models = {};
      this.subFormsModels["do"] = [];
      this.subFormsModels["action"] = [];
      try {
        this.subForms[0]["children"] = [];
        this.subForms[1]["children"] = [];
        this.$refs.form.resetValidation();
      } catch (_) {
        console.log(_);
      }
    },

    clearForm() {
      try {
        this.$refs.form.resetValidation();
      } catch (e) {
        console.log(e);
      }
      this.form = [];
    },

    validateRules(field) {
      if (field.required) {
        return [(v) => !!v || `${field.label} can't be empty`];
      }
    },

    async loadData() {
      try {
        const route = this.$route.params;
        const response = await axios.get(
          `${this.$api}/${route.menu}/${route.subMenu}/all`
        );
        const data = response.data.data;

        // set headers
        if (data.length > 0) {
          for (var i in data[0]) {
            this.headers.push({
              text: i,
              value: i,
              sortable: i == "date" ? false : true,
              align: i == "id" ? "start" : "center",
            });
          }
          this.headers.push({
            text: "actions",
            value: "actions",
            sortable: false,
          });
        }
        this.actualData = data;

        for (var j = 0; j < data.length; j++) {
          var obj;
          if (data[j]["inttower"]) {
            obj = this.listTowers.find((e) => e["id"] == data[j]["inttower"]);
            data[j]["inttower"] = obj ? obj["vcname"] : "Undefined";
          }

          if (data[j]["intcategory"]) {
            obj = this.listCategories.find(
              (e) => e["id"] == data[j]["intcategory"]
            );
            data[j]["intcategory"] = obj ? obj["vcname"] : "Undefined";
          }

          if (data[j]["intzone"]) {
            obj = this.listZones.find((e) => e["id"] == data[j]["intzone"]);
            data[j]["intzone"] = obj ? obj["vcname"] : "Undefined";
          }

          if (data[j]["inttype"]) {
            obj = this.listTypes.find((e) => e["id"] == data[j]["inttype"]);
            data[j]["inttype"] = obj ? obj["vcname"] : "Undefined";
          }
        }
        this.data = data;
      } catch (err) {
        console.log(err);
      }
    },

    async openDialog(itemId) {
      EventBus.$emit("startLoading");
      this.clearModels();
      this.doIndex = 0;
      this.actionIndex = 0;
      await this.getListUsers();
      await this.getListStatus();
      if (itemId) {
        const route = this.$route.params;
        const response = await axios.get(
          `${this.$api}/${route.menu}/${route.subMenu}/id/${itemId}`
        );
        console.log("response", response);

        const item = response.data.data.item;
        const doItem = response.data.data.do;
        const actionItem = response.data.data.action;

        for (this.doIndex; this.doIndex < doItem.length; this.doIndex++) {
          console.log("for loop " + this.doIndex);
          // set pic
          const result = this.listUsers.filter(
            (v) => v["id"] == doItem[this.doIndex]["pic"]
          );
          console.log("result", result);
          if (result) {
            doItem[this.doIndex]["pic"] = result[0];
          }

          // set status (now it's still dummy)
          const res = this.listStatus.filter(
            (v) => v["id"] == doItem[this.doIndex]["vcstatus"]
          );

          console.log("res status", res);

          if (res.length > 0) {
            console.log("res true");
            doItem[this.doIndex]["vcstatus"] = res[0];
          } else {
            doItem[this.doIndex]["vcstatus"] = this.listStatus[0];
          }
          this.addDoForm(false);
          console.log("add do form " + this.doIndex);
        }

        for (
          this.actionIndex;
          this.actionIndex < actionItem.length;
          this.actionIndex++
        ) {
          // set pic
          const result = this.listUsers.filter(
            (v) => v["id"] == actionItem[this.actionIndex]["pic"]
          );
          console.log("result", result);
          if (result) {
            actionItem[this.actionIndex]["pic"] = result[0];
          }

          // set status (now it's still dummy)
          const res = this.listStatus.filter(
            (v) => v["id"] == actionItem[this.actionIndex]["vcstatus"]
          );

          console.log("res status", res);

          if (res.length > 0) {
            console.log("res true");
            actionItem[this.actionIndex]["vcstatus"] = res[0];
          } else {
            actionItem[this.actionIndex]["vcstatus"] = this.listStatus[0];
          }
          this.addActionForm(false);
          console.log("add action form " + this.actionIndex);
        }

        console.log("doItem", doItem);
        console.log("actionItem", actionItem);

        this.isEditing = true;
        this.dialog = true;
        this.models = item;
        this.subFormsModels = [];
        this.subFormsModels["action"] = actionItem;
        this.subFormsModels["do"] = doItem;

        console.log("models", this.models);
        console.log("subform", this.subForms);
        console.log("subformmodels", this.subFormsModels);
        this.checkIfHasChild();
      } else {
        this.data["dtstart"] = undefined;
        this.data["dtfinish"] = undefined;
        this.isEditing = false;
        this.clearModels();
      }
      EventBus.$emit("stopLoading");
    },

    async submitData(isEdit) {
      EventBus.$emit("startLoading");
      try {
        const route = this.$route.params;
        if (this.models["dtperiode"]) {
          let date = this.models["dtperiode"] + "-01";
          this.models["dtperiode"] = date;
          // console.log(date);
          // console.log(this.models);
        }
        var obj = this.models;
        var obj2 = this.subFormsModels;
        const lastObj = Object.assign(obj, obj2);

        if (obj["dtstart"] || obj["dtfinish"]) {
          console.log(obj);
          var start = new Date(obj["dtstart"]);
          let dateStart = JSON.stringify(start);
          dateStart = dateStart.slice(1, 11);

          var end = new Date(obj["dtfinish"]);
          let dateEnd = JSON.stringify(end);
          dateEnd = dateEnd.slice(1, 11);

          // console.log(dateStart);

          var momentStart = moment(dateStart, "YYYY-MM-DD");
          var momentEnd = moment(dateEnd, "YYYY-MM-DD");

          var dtstart = momentStart.format("YYYY-MM-DD h:mm:ss");
          var dtfinish = momentEnd.format("YYYY-MM-DD h:mm:ss");

          if (route.subMenu == "loss-registration") {
            obj["dtstart"] = dtstart;
            obj["dtfinish"] = dtfinish;
            obj["decduration"] = 0;
          }
        }

        try {
          obj["inttype"] = obj["inttype"]["id"];
          obj["inttower"] = obj["inttower"]["id"];
          obj["intcategory"] = obj["intcategory"]["id"];
          obj["intzone"] = obj["intzone"]["id"];
          obj["intfloc"] = obj["intfloc"]["id"];
          obj["intequipment"] = obj["intequipment"]["id"];
        } catch (e) {
          console.log(e);
        }

        for (var i = 0; i < lastObj["do"].length; i++) {
          lastObj["do"][i]["pic"] = lastObj["do"][i]["pic"]["id"];
          lastObj["do"][i]["vcstatus"] = lastObj["do"][i]["vcstatus"]["id"];
        }

        for (var j = 0; j < lastObj["action"].length; j++) {
          lastObj["action"][j]["pic"] = lastObj["action"][j]["pic"]["id"];
          lastObj["action"][j]["vcstatus"] =
            lastObj["action"][j]["vcstatus"]["id"];
        }

        console.log("last obj", lastObj);

        if (isEdit) {
          await axios.post(
            `${this.$api}/${route.menu}/${route.subMenu}/update/` +
              this.models.id,
            lastObj
          );
        } else {
          await axios.post(
            `${this.$api}/${route.menu}/${route.subMenu}/add`,
            lastObj
          );
        }
        this.clearModels();
        this.dialog = false;
        await this.initData();
      } catch (err) {
        console.log(err);
      }
      EventBus.$emit("stopLoading");
    },

    async deleteData(DataId) {
      EventBus.$emit("startLoading");
      this.dialogDelete = false;
      try {
        const route = this.$route.params;
        await axios.delete(
          `${this.$api}/${route.menu}/${route.subMenu}/delete/` + DataId
        );
        await this.initData();
      } catch (err) {
        console.log(err);
      }
      EventBus.$emit("stopLoading");
    },

    checkIfHasChild(label) {
      if (label === "Do" || label === "Action") return true;
      else return false;
    },
    deleteDoForm(id, index) {
      id = this.subForms[0]["children"]
        .map(function (img) {
          return img[0].id;
        })
        .indexOf(id);
      this.subForms[0]["children"].splice(id, 1);
      this.subFormsModels["do"].splice(index, 1);

      console.log(this.subFormsModels);
    },
    addDoForm(isWithSubFormModel) {
      var index = this.doIndex;

      if (isWithSubFormModel) {
        var obj = {};
        obj["vcvalue"] = "";
        obj["pic"] = "";
        obj["due_date"] = "";
        obj["vcstatus"] = "";

        this.subFormsModels["do"].push(obj);
      }

      this.subForms[0]["children"].push([
        {
          id: index,
          label: "Value",
          model: `vcvalue`,
          type: "text",
          required: true,
        },
        {
          id: index,
          label: "Pic",
          model: `pic`,
          type: "select",
          required: true,
        },
        {
          id: index,
          label: "Due Date",
          model: `due_date`,
          type: "date",
          required: true,
        },
        {
          id: index,
          label: "Status",
          model: `vcstatus`,
          type: "select",
          required: true,
        },
      ]);
      if (isWithSubFormModel) {
        this.doIndex++;
      }
      console.log(this.subFormsModels);
      console.log(this.subForms);
    },
    deleteActionForm(id, index) {
      id = this.subForms[1]["children"]
        .map(function (img) {
          return img[0].id;
        })
        .indexOf(id);
      this.subForms[1]["children"].splice(id, 1);
      this.subFormsModels["action"].splice(index, 1);

      console.log(this.subFormsModels);
    },
    addActionForm(isWithSubFormModel) {
      var index = this.actionIndex;

      if (isWithSubFormModel) {
        var obj = {};
        obj["vcvalue"] = "";
        obj["pic"] = "";
        obj["due_date"] = "";
        obj["vcstatus"] = "";

        this.subFormsModels["action"].push(obj);
      }

      this.subForms[1]["children"].push([
        {
          id: index,
          label: "Value",
          model: `vcvalue`,
          type: "text",
          required: true,
        },
        {
          id: index,
          label: "Pic",
          model: `pic`,
          type: "select",
          required: true,
        },
        {
          id: index,
          label: "Due Date",
          model: `due_date`,
          type: "date",
          required: true,
        },
        {
          id: index,
          label: "Status",
          model: `vcstatus`,
          type: "select",
          required: true,
        },
      ]);
      if (isWithSubFormModel) {
        this.actionIndex++;
      }
      console.log(this.subFormsModels);
      console.log(this.subForms);
    },
  },
};
</script>

<style scoped>
.sticky-bar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
}
.weight-600 {
  font-weight: 900 !important;
}

.col-lg,
.col-lg-6 .col-md,
.col-md-6,
.col-sm,
.col-sm-6,
.col {
  width: 100%;
  padding: 0 !important;
}

/* .v-text-field {
  width: 200px !important;
} */

.v-text-field-plan {
  width: 1025px !important;
}

.align-item-right {
  align-items: flex-end !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.radius {
  border-radius: 0.6rem !important;
}

.button-p {
  color: white;
  text-align: center !important;
}

h4,
h2 {
  font-weight: 500;
}

h2 {
  color: black;
}

.span-class {
  font-weight: 600 !important;
}

table {
  border-collapse: collapse !important;
  width: 100% !important;
  margin: auto !important;
}

th {
  background: #eb5353 !important;
  color: white !important;
}

td,
th {
  border: 1px solid #d1d5db !important;
  font-weight: 500;
  font-size: 20px;
  width: 16%;
}

tr:nth-child(even) {
  background-color: #fff !important;
}

.v-text-field-select {
  width: 148px !important;
}

.v-text-field-ayolah {
  width: 290px !important;
}
</style>

<style lang="scss" scoped>
::v-deep th {
  background-color: #eb5353 !important;
  color: white !important;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
</style>

<style></style>
