<template>
  <div :class="route.data == 'backlog-week' || flowChart ? 'chart-height' : ''">
    <v-row
      v-if="
        (!tableView && !pieChart && !flowChart) || route.data == 'backlog-week'
      "
    >
      <v-col :cols="routeProp ? '12' : '10'">
        <div
          class="d-flex flex-row align-center justify-center primary--text font-weight-medium"
        >
          <p>{{ subtitle }}</p>
        </div>
        <div class="shadow">
          <p
            class="text-center font-weight-bold primary--text pt-5"
            v-if="route.data == 'backlog-week'"
          >
            Bar Chart Backlog Week
          </p>

          <div>
            <GChart
              v-if="route.data == 'comparative-downtime'"
              type="ColumnChart"
              :data="chartData"
              :options="options"
            />
            <v-row>
              <v-col :cols="route.data == 'finished-ontime' ? '10' : '12'">
                <div v-if="route.data == 'finished-ontime'" class="container">
                  <p>On Time Finish PM Order</p>
                </div>
                <column-chart
                  v-if="
                    (chartType == 'vertical' || route.data == 'backlog-week') &&
                    route.data != 'comparative-downtime'
                  "
                  :data="data"
                  :library="options"
                  legend="bottom"
                  :colors="colors"
                  :dataset="dataset"
                  :events="events"
                  adapter="google"
                >
                </column-chart>
              </v-col>

              <v-col v-if="route.data == 'finished-ontime'" cols="2">
                <div class="container">
                  <p>% OnTime</p>
                </div>
                <column-chart
                  class="pt-16"
                  :data="onTimeData"
                  :library="onTimeOptions"
                  legend="bottom"
                  :colors="onTimeColors"
                  :dataset="dataset"
                  :events="events"
                  adapter="google"
                >
                </column-chart>
              </v-col>
            </v-row>
            <bar-chart
              v-if="chartType == 'horizontal'"
              :data="data"
              :library="options"
              legend="bottom"
              :colors="colors"
              :events="events"
              adapter="google"
            >
            </bar-chart>
          </div>
          <v-data-table
            v-if="chartType == 'table'"
            :headers="headersTable"
            :items="itemsTable"
            :items-per-page="5"
          >
            <template v-slot:[`item.status`]="{ item }">
              <center>
                <div class="status" :class="item.status">
                  {{ item.status }}
                </div>
              </center>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col
        v-if="!routeProp"
        cols="2"
        align-self="start"
        class="mt-10 comment-position"
      >
        <v-btn
          @click.stop="drawer = !drawer"
          color="primary"
          class="text-none"
          style="border-radius: 10px"
          elevation="0"
          >Comment<v-icon right dark> mdi-comment </v-icon></v-btn
        >
        <div v-if="tower" class="mb-2 mt-5">Tower:</div>
        <v-select
          v-if="tower"
          :items="towers"
          item-text="name"
          v-model="selectedTower"
          label="Tower"
          @change="changeData()"
          solo
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
        <div v-if="year" class="mb-2" :class="tower ? '' : 'mt-5'">Year:</div>
        <v-select
          v-if="year"
          :items="years"
          v-model="selectedYear"
          label="Year"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
        <div class="" v-if="pic">PIC:</div>
        <v-select
          v-if="pic"
          :items="pics"
          item-text="vcname"
          item-value="vcname"
          v-model="selectedPic"
          label="PIC"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
        <div v-if="month" class="mb-2" :class="tower || year ? '' : 'mt-5'">
          Month:
        </div>
        <v-select
          v-if="month"
          :items="months"
          v-model="selectedMonth"
          label="Month"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
        <div v-if="log">Status BackLog:</div>
        <v-switch
          class="ml-3"
          v-model="selectedLog"
          @change="changeData()"
          true-value="1"
          false-value="0"
          v-if="log"
          hide-details
          inset
        ></v-switch>
        <div class="" v-if="CcName">CC Name:</div>
        <v-select
          v-if="CcName"
          :items="ccNames"
          item-text="vcname"
          item-value="vcname"
          v-model="selectedCc"
          label="CC Name"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
      </v-col>

      <v-navigation-drawer
        v-if="!routeProp"
        width="344"
        v-model="drawer"
        right
        absolute
        temporary
      >
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-row">
              <v-list-item-title class="weight-600"
                >Comment Rooms</v-list-item-title
              >

              <v-btn icon @click="closeDrawer">
                <v-icon> mdi-window-close </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list dense>
          <v-list-item-content>
            <div
              class="d-flex flex-row"
              v-for="(comment, i) in userComments"
              :key="i"
            >
              <img src="@/assets/user.png" class="smol-img ml-4" />
              <div class="d-flex flex-column ml-4">
                <p class="mb-2 weight-600">{{ comment.userName }}</p>
                <p>{{ comment.createdAt }}</p>
                <p class="">{{ comment.comments }}</p>
              </div>
            </div>
          </v-list-item-content>
        </v-list>

        <template v-slot:append>
          <div class="text-center">
            <v-pagination
              @input="onChangePagination"
              :total-visible="5"
              v-model="paginationCounter"
              :length="paginationLength"
            ></v-pagination>
          </div>

          <div class="pa-2">
            <v-text-field
              v-model="form.txtComment"
              @click:append="add"
              append-icon="mdi-send"
              label="Type Your Comment"
              outlined
            >
            </v-text-field>
          </div>
        </template>
      </v-navigation-drawer>
    </v-row>
    <TableView
      v-if="tableView"
      :resData="resData"
      :key="key"
      :route="route"
      :titleProp="titleProp"
      :yearProp="yearProp"
      :picProp="picProp"
      :hasFilterProp="hasFilterProp"
      :class="route.data == 'backlog-week' ? 'mt-16 pt-16' : ''"
    />
    <PieChart
      v-if="pieChart"
      :hasFilterProp="hasFilterProp"
      :routeProp="routeProp"
      :yearProp="yearProp"
      :picProp="picProp"
      :ccProp="ccProp"
    />
    <FlowChart
      v-if="flowChart"
      :hasFilterProp="hasFilterProp"
      :routeProp="routeProp"
      :yearProp="yearProp"
      :picProp="picProp"
      :ccProp="ccProp"
    />
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import axios from "axios";
import TableView from "./TableView.vue";
import PieChart from "./ChartPie.vue";
import FlowChart from "./FlowChart.vue";
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "ChartDataView",
  components: {
    TableView,
    GChart,
    PieChart,
    FlowChart,
  },
  data: () => ({
    paginationCounter: 1,
    paginationLength: 1,
    drawer: null,
    userComments: [],
    form: {
      txtComment: "",
    },
    log: false,
    key: 0,
    tower: true,
    year: true,
    month: true,
    resData: null,
    chartData: [],
    onTimeData: [],
    onTimeOptions: {
      annotations: {
        textStyle: {
          color: "black",
        },
        alwaysOutside: true,
      },
      height: 400,
      series: {},
      focusTarget: "category",
      legend: {
        position: "bottom",
      },
      isStacked: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    options: {
      annotations: {
        textStyle: {
          color: "black",
        },
        alwaysOutside: true,
      },
      height: 400,
      series: {},
      focusTarget: "category",
      legend: {
        position: "bottom",
      },
      isStacked: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 20,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    colors: ["#1890FF", "#F59E0B", "#9CA3AF", "#FACC14", "#8543E0"],
    onTimeColors: ["#FC7900", "#F59E0B", "#9CA3AF", "#FACC14", "#8543E0"],
    subtitle: "",
    title: "",
    towers: [
      {
        name: "All Tower",
        value: 0,
      },
      {
        name: "Tower S1",
        value: 1,
      },
      {
        name: "Tower S2",
        value: 2,
      },
    ],
    years: [],
    months: [
      {
        text: "January",
        value: 1,
      },
      {
        text: "February",
        value: 2,
      },
      {
        text: "March",
        value: 3,
      },
      {
        text: "April",
        value: 4,
      },
      {
        text: "May",
        value: 5,
      },
      {
        text: "June",
        value: 6,
      },
      {
        text: "July",
        value: 8,
      },
      {
        text: "August",
        value: 8,
      },
      {
        text: "September",
        value: 9,
      },
      {
        text: "October",
        value: 10,
      },
      {
        text: "November",
        value: 11,
      },
      {
        text: "December",
        value: 12,
      },
    ],
    selectedTower: 0,
    selectedYear: 2021,
    selectedMonth: 12,
    selectedTitle: null,
    data: [],
    lineData: [],
    headersTable: [],
    itemsTable: [],
    chartType: "vertical",
    dataset: {
      borderWidth: 2,
      barThickness: 60,
      stack: "base",
      barShowStroke: true,
    },
    tableView: false,
    pieChart: false,
    flowChart: false,
    pic: false,
    selectedPic: 0,
    selectedLog: "0",
    selectedCc: 1,
    LogStatus: [],
    pics: [],
    CcName: false,
    ccNames: [],
    anotherChartType: "",
    events: {
      select: () => {
        console.log("select");
      },
    },
    route: "",
    apiRequestTimer: null,
  }),
  props: [
    "routeProp",
    "towerProp",
    "yearProp",
    "monthProp",
    "picProp",
    "logProp",
    "ccProp",
    "titleProp",
    "hasFilterProp",
  ],
  async created() {
    if (!this.routeProp) {
      this.$watch(
        () => {
          this.$route;
          return {};
        },
        () => {
          this.getDefaultData();
        }
      );
    }
  },
  mounted() {
    this.apiRequestTimer = setTimeout(() => {
      this.getDefaultData();
    }, 1000);
  },
  beforeDestroy() {
    clearTimeout(this.apiRequestTimer);
  },

  methods: {
    async getDefaultData() {
      console.log("get default data");
      this.chartData = [];
      this.onTimeData = [];
      this.route = this.$route.params;
      if (this.routeProp) {
        this.route = this.routeProp;
      }
      this.anotherChartType = "";
      this.setTitle();

      if (this.route.data == "backlog-week") {
        this.anotherChartType = "vertical";
      }

      var d = new Date();
      for (var i = 2014; i <= d.getFullYear(); i++) {
        this.years.push(i);
      }
      for (var j = 0; j <= 1; j++) {
        this.LogStatus.push(j);
      }
      this.years.reverse();

      this.selectedYear = d.getFullYear();
      this.selectedMonth = d.getMonth();

      switch (this.route.data) {
        case "pdca-improvement":
        case "backlog-month":
          this.tableView = true;
          this.pieChart = false;
          this.flowChart = false;
          break;
        case "work-preparation":
        case "base-tasktime":
        case "ytd-realization":
        case "pmorder-ratio":
          this.pieChart = true;
          this.tableView = false;
          this.flowChart = false;
          break;
        case "work-order":
          this.flowChart = true;
          this.tableView = false;
          this.pieChart = false;
          break;
        case "backlog-week":
          this.anotherChartType = null;
          this.tableView = true;
          this.pieChart = false;
          this.flowChart = false;
          break;
        default:
          this.tableView = false;
          this.pieChart = false;
          this.flowChart = false;
          break;
      }
      this.setChartParameters();
      window.addEventListener("resize", () => {
        this.setHeight();
      });
      this.setHeight();
      // console.log(
      //   "options height:",
      //   document.getElementById("chart-id").clientHeight
      // );
      // console.log(JSON.parse(localStorage.getItem("data")).access_token);
      console.log("tower", this.selectedTower);
      await this.changeData();
      // await this.loadComment();
    },
    async getListZones() {
      this.pics = JSON.parse(localStorage.getItem("pics"));
    },

    async getListCcNames() {
      this.ccNames = JSON.parse(localStorage.getItem("ccNames"));
    },

    setHeight() {
      // console.log(
      //   "set height executed",
      //   document.getElementById("chart-id").clientHeight
      // );
      this.options.height =
        document.getElementById("chart-id").clientHeight * 0.6;
      this.options.width =
        document.getElementById("chart-id").clientWidth * 0.61;

      if (this.routeProp) {
        this.options.height = "100%";
        this.options.width = "100%";
      }
    },
    async setChartParameters() {
      var route = this.route;
      var data = route.data;

      var d = new Date();
      this.selectedTower = 0;
      this.selectedPic = 0;
      this.selectedLog = "0";
      this.selectedCc = 1;
      this.selectedYear = d.getFullYear();
      this.selectedMonth = d.getMonth();

      this.tower = false;
      this.year = false;
      this.month = false;
      this.log = false;
      this.pic = false;
      this.CcName = false;

      if (this.hasFilterProp) {
        this.selectedTower = this.towerProp == "All Tower" ? undefined : this.towerProp;
        this.selectedYear = this.yearProp;
        this.selectedMonth = this.monthProp;
        this.selectedPic = this.picProp == 'All PIC' ? undefined : this.picProp;
        this.selectedLog = this.logProp;
        this.selectedCc = this.ccProp == 'All CCName' ? undefined : this.ccProp;
        this.selectedTitle = this.titleProp;

        if (this.towerProp) this.tower = true;
        if (this.yearProp) this.year = true;
        if (this.monthProp) this.month = true;
        if (this.picProp) this.pic = true;
        if (this.logProp) this.log = true;
        if (this.ccProp) this.CcName = true;
        if (this.titleProp) this.title = true;
      }
      switch (data) {
        case "oee-monitoring":
        case "topten-downtime":
        case "topten-dtmonitoring":
        case "cost-drivers-budget":
        case "mnr-order-cost":
          this.year = true;
          break;
        case "actual-cost-centre":
          this.year = true;
          EventBus.$emit("startLoading");
          await this.getListCcNames();
          EventBus.$emit("stopLoading");
          break;
        case "planning-review":
        case "finished-ontime":
          this.year = true;
          this.pic = true;
          this.selectedPic = 0;
          EventBus.$emit("startLoading");
          await this.getListZones();
          EventBus.$emit("stopLoading");
          break;
        case "primary-stopped":
        case "breakdown-stopped":
        case "category-failure":
        case "area-failure":
          this.tower = true;
          break;
        case "weekly-downtime":
        case "comparative-downtime":
          this.tower = true;
          this.year = true;
          break;
        case "daily-downtime":
          this.tower = true;
          this.year = true;
          this.month = true;
          break;
        case "oee-journey":
          this.pic = false;
          break;
        case "backlog-week":
          this.log = true;
          this.pic = true;
          this.year = true;
          this.selectedPic = 0;
          EventBus.$emit("startLoading");
          await this.getListZones();
          EventBus.$emit("stopLoading");
          break;
        case "mnr-cost-perzone":
        case "comparison-order":
        case "pmorder-ratio":
          this.year = true;
          this.pic = true;
          this.CcName = true;
          EventBus.$emit("startLoading");
          await this.getListCcNames();
          await this.getListZones();
          EventBus.$emit("stopLoading");
          break;
        case "maintenance-cost-budget":
          this.year = true;
          this.CcName = true;
          EventBus.$emit("startLoading");
          await this.getListCcNames();
          EventBus.$emit("stopLoading");
          break;
      }
    },
    mergeData(data) {
      const result = {};

      data.forEach((basket) => {
        for (let [key, value] of Object.entries(basket["data"])) {
          if (result[key]) {
            result[key] += value;
          } else {
            //(6)
            result[key] = value;
          }
        }
      });
      return result;
    },
    changeData() {
      this.setData();
    },
    setTitle() {
      if (!this.routeProp) {
        var route = this.route;
        var localStorageData = JSON.parse(localStorage.getItem("data"));
        localStorageData = localStorageData["data"][1]["menu-content"].find(
          (e) => e.vcurl == `/chart/${route.menu}`
        );
        localStorageData = localStorageData["childrens"].find(
          (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}`
        );

        this.title = localStorageData.vcname;
        EventBus.$emit("changeTitle", this.title);

        localStorageData = localStorageData["childrens"].find(
          (e) =>
            e.vcurl == `/chart/${route.menu}/${route.subMenu}/${route.data}`
        );

        this.subtitle = localStorageData.vcname;
      }
    },
    async setData() {
      console.log("set data testing");
      this.resData = "";
      this.key++;
      this.chartData = [];
      this.onTimeData = [];
      EventBus.$emit("startLoading");
      this.options["isStacked"] = true;
      this.options["series"] = {};
      this.options["colors"] = [
        "#1890FF",
        "#FC7900",
        "#979797",
        "#FACC14",
        "#8543E0",
      ];
      this.options["legend"] = {
        position: "bottom",
      };
      this.options["enableInteractivity"] = true;
      this.options["chartArea"] = {};
      try {
        var dataStorage = JSON.parse(localStorage.getItem("data"));
        axios.defaults.headers.common = {
          Authorization: `Bearer ${dataStorage.access_token}`,
        };

        var route = this.route;

        this.setTitle();
        const params = {};

        if (this.year) {
          params["year"] = this.selectedYear;
        }
        if (this.tower) {
          params["tower"] = this.selectedTower;
        }
        if (this.month) {
          params["month"] = this.selectedMonth;
        }
        if (this.pic) {
          params["pic"] = this.selectedPic;
          params["zone"] = this.selectedPic;
        }
        if (this.log) {
          params["backlog"] = this.selectedLog;
        }
        if (this.CcName) {
          params["ccname"] = this.selectedCc;
        }
        if (this.title) {
          params["title"] = this.selectedTitle;
        }

        this.chartType = "vertical";
        this.anotherChartType = "";
        this.options["vAxes"] = {};

        if (route.data == "backlog-week") {
          this.anotherChartType = "vertical";
        }

        if (route.data == "planning-review") {
          this.options["legend"] = {
            position: "none",
          };
        }

        if (route.data == "oee-journey" || route.data == "oee-monitoring") {
          this.options["isStacked"] = false;
        }

        if (route.data == "category-failure") {
          this.options["series"] = {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 0 },
            2: {
              targetAxisIndex: 0,
              type: "line",
              pointSize: 10,
            },
          };
        }

        if (
          route.data == "area-failure" ||
          route.data == "weekly-downtime" ||
          route.data == "daily-downtime"
        ) {
          this.options["series"] = {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 0 },
            2: { targetAxisIndex: 0 },
            3: { targetAxisIndex: 0 },
            4: { targetAxisIndex: 0 },
            5: {
              targetAxisIndex: 0,
              type: "line",
              pointSize: 10,
            },
          };
        }

        if (route.data == "maintenance-cost-budget") {
          this.options["colors"] = [
            "#8D8D8D",
            "#FECF26",
            "#7166F9",
            "#DA602B",
            "#1821FF",
          ];
          this.options["series"] = {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 0 },
            2: {
              targetAxisIndex: 0,
              type: "line",
              lineDashStyle: [6, 6],
              pointSize: 0,
            },
            3: {
              targetAxisIndex: 0,
              type: "line",
              pointSize: 0,
              lineWidth: 6,
            },
            4: {
              targetAxisIndex: 1,
              type: "line",
              lineWidth: 6,
              pointSize: 0,
              format: {
                type: "percent",
                fractionDigits: 2,
              },
            },
          };
          this.options["vAxes"] = {
            1: { format: "#'%'" },
          };
          // this.options["hAxes"] = {
          //   4: { format: "percent" },
          // };
        }

        this.options["bar"] = { groupWidth: "40" };

        if (
          route.data == "topten-downtime" ||
          route.data == "planning-review" ||
          route.data == "cost-drivers-budget" ||
          route.data == "mnr-order-cost"
        ) {
          this.chartType = "horizontal";
          this.options["bar"] = { groupWidth: "15" };
          this.options["chartArea"] = { left: 250, top: 50, width: "100%" };
        } else if (route.data == "topten-dtmonitoring") {
          this.chartType = "table";
        } else {
          this.options["bar"] = { groupWidth: "60%" };
        }

        // console.log("chart type", this.chartType);

        if (route.data == "finished-ontime" || route.data == "backlog-week") {
          this.options["colors"] = ["#1890FF", "#2FC25B", "#F59E0B"];
        }

        if (route.data == "actual-cost-centre") {
          this.options["colors"] = [
            "#1890FF",
            "#8543E0",
            "#EE5F77",
            "#F59E0B",
            "#F7CF33",
            "#4AC76F",
            "E91E63",
          ];

          this.options["series"] = {
            6: {
              // targetAxisIndex: 0,
              type: "line",
              // pointSize: 0,
              // lineWidth: 6,
            },
          };
          this.options["vAxes"] = {
            0: { format: "$" },
          };
        }

        if (
          route.data == "mnr-cost-perzone" ||
          route.data == "comparison-order"
        ) {
          this.options["colors"] = [
            "#1890FF",
            "#4AC76F",
            "#9CA3AF",
            "#FBD437",
            "#F59E0B",
          ];
        }

        if (route.data == "comparison-order") {
          this.options["vAxes"] = {
            0: { format: "#'%'" },
          };
        }

        if (route.data == "cost-drivers-budget") {
          this.options["isStacked"] = false;
          this.options["bar"] = { groupWidth: "70%" };
          this.onTimeOptions["bar"] = { groupWidth: "60%" };
          this.options["colors"] = ["#FC7900", "#1890FF"];
        }

        if (route.data == "finished-ontime") {
          this.options["isStacked"] = false;
          this.options["bar"] = { groupWidth: "100%" };
          this.onTimeOptions["bar"] = { groupWidth: "60%" };
        }

        if (route.data == "mnr-order-cost") {
          this.options["isStacked"] = "percent";
          this.options["series"] = {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 0 },
            2: { targetAxisIndex: 0 },
            3: { targetAxisIndex: 0 },
            4: { targetAxisIndex: 0 },
          };
          this.options["hAxis"] = {
            minValue: 0,
            ticks: [0, 0.2, 0.4, 0.6, 0.8, 1],
          };
          this.options["vAxes"] = {
            0: { format: "##.##%" },
          };
          this.options["bar"] = { groupWidth: "60%" };
          this.options["colors"] = [
            "#1890FF",
            "#4AC76F",
            "#9CA3AF",
            "#FBD437",
            "#EB5353",
          ];
        }

        if (route.data == "mnr-cost-perzone") {
          this.options["vAxes"] = {
            0: { format: "$" },
          };
        }

        const resData = await axios.get(
          `${this.$api}/chart/${route.menu}/${route.subMenu}/${route.data}`,
          { params }
        );
        console.log("res data true", resData);
        this.resData = resData;
        if (resData.status == 200) {
          const data = resData.data.data;
          // console.log("data", data);
          if (
            this.chartType != "table" ||
            this.anotherChartType == "vertical"
          ) {
            if (route.data == "comparative-downtime") {
              const increase = {};
              const decrease = {};
              const total = {};
              const low = {};
              console.log("comparative");
              console.log(data);
              const titles = [
                "Name",
                "Low",
                "Increase",
                "Decrease",
                "Total",
                { role: "annotation", type: "string" },
              ];
              this.chartData.push(titles);
              data.forEach((object) => {
                const detail = object["detail"];
                switch (detail["status"]) {
                  case "increase":
                    increase[object["index"]] = [
                      detail["high"],
                      false,
                      "annotation",
                    ];
                    this.chartData.push([
                      object["index"],
                      object["detail"]["low"],
                      object["detail"]["high"],
                      0,
                      0,
                      object["detail"]["high"],
                    ]);
                    break;
                  case "decrease":
                    decrease[object["index"]] = [
                      detail["high"],
                      false,
                      "annotation",
                    ];
                    this.chartData.push([
                      object["index"],
                      object["detail"]["low"],
                      0,
                      object["detail"]["high"],
                      0,
                      object["detail"]["high"],
                    ]);
                    break;
                  case "total":
                    total[object["index"]] = [
                      detail["high"],
                      false,
                      "annotation",
                    ];
                    this.chartData.push([
                      object["index"],
                      object["detail"]["low"],
                      0,
                      0,
                      object["detail"]["high"],
                      object["detail"]["high"],
                    ]);
                    break;
                }
                low[object["index"]] = detail["low"];
              });
              console.log("chart data", this.chartData);
              const finalIncrease = {
                name: "increase",
                data: increase,
              };
              const finalDecrease = {
                name: "decrease",
                data: decrease,
              };
              const finalTotal = {
                name: "total",
                data: total,
              };
              const finalLow = {
                name: "low",
                data: low,
              };
              this.data.push(finalLow);
              this.data.push(finalIncrease);
              this.data.push(finalDecrease);
              this.data.push(finalTotal);
              console.log(this.data);

              this.options["colors"] = [
                "transparent",
                "#1890FF",
                "#F59E0B",
                "#9CA3AF",
                "#8543E0",
              ];

              this.options["enableInteractivity"] = false;

              this.options["series"] = {
                0: {
                  visibleInLegend: false,
                  tooltip: false,
                },
              };
            } else if (route.data == "actual-cost-centre") {
              this.data = [];
              var cData1 = {
                name: "01 S1",
                data: {},
              };
              var cData2 = {
                name: "02 S2",
                data: {},
              };
              var cData3 = {
                name: "03 M&R",
                data: {},
              };
              var cData4 = {
                name: "04 WWTP",
                data: {},
              };
              var cData5 = {
                name: "05 FMng",
                data: {},
              };
              var cData6 = {
                name: "06 Others",
                data: {},
              };
              var totalCostData = {
                name: "Total",
                data: {},
              };

              const temp = data["chart"];
              temp.forEach((realData) => {
                cData1["data"][realData["month"]] =
                  realData["detail"][0]["value"];
                cData2["data"][realData["month"]] =
                  realData["detail"][1]["value"];
                cData3["data"][realData["month"]] =
                  realData["detail"][2]["value"];
                cData4["data"][realData["month"]] =
                  realData["detail"][3]["value"];
                cData5["data"][realData["month"]] =
                  realData["detail"][4]["value"];
                cData6["data"][realData["month"]] =
                  realData["detail"][5]["value"];
                totalCostData["data"][realData["month"]] = realData["total"];
              });
              this.data.push(cData1);
              this.data.push(cData2);
              this.data.push(cData3);
              this.data.push(cData4);
              this.data.push(cData5);
              this.data.push(cData6);
              this.data.push(totalCostData);
            } else if (route.data == "cost-drivers-budget") {
              console.log("data", data);
              var dataBudget = {};
              var dataReal = {};
              data.forEach((object) => {
                if (object.detail[0].budget) {
                  dataBudget[object.ccname] = object.detail[0].budget;
                }
                if (object.detail[0].realization) {
                  dataReal[object.ccname] = object.detail[0].realization;
                }
              });
              console.log("data real", dataReal);
              this.data = [];
              this.options["colors"] = [];
              if (Object.keys(dataReal).length !== 0) {
                console.log("data real true");
                this.data.push({
                  data: dataReal,
                  name: "Realization",
                });
                this.options["colors"].push("#FC7900");
              }
              if (Object.keys(dataBudget).length !== 0) {
                this.data.push({
                  data: dataBudget,
                  name: "Budget",
                });
                this.options["colors"].push("#1890FF");
              }
              this.chartData = this.data;
              console.log("chart data test", this.chartData);
            } else if (
              route.data == "mnr-cost-perzone" ||
              route.data == "comparison-order" ||
              route.data == "mnr-order-cost"
            ) {
              var calOrder = { name: "Cal_Order", data: {} };
              var pmOrder = { name: "PM_Order", data: {} };
              var cmOrder = { name: "CM_Order", data: {} };
              var bdOrder = { name: "BD_Order", data: {} };
              var modOrder = { name: "Mod_Order", data: {} };
              data.forEach((object) => {
                var objParam, objDetail;
                if (route.data == "mnr-order-cost") {
                  objParam = object.ccname;
                  objDetail = object.detail.value;
                } else {
                  objParam = object.month;
                  objDetail = object.detail;
                }
                calOrder["data"][objParam] = objDetail["Cal_Order"];
                pmOrder["data"][objParam] = objDetail["PM_Order"];
                cmOrder["data"][objParam] = objDetail["CM_Order"];
                bdOrder["data"][objParam] = objDetail["BD_Order"];
                modOrder["data"][objParam] = objDetail["Mod_Order"];
              });
              this.data = [calOrder, pmOrder, cmOrder, bdOrder, modOrder];
              console.log("this data", this.data);
              this.chartData = this.data;
            } else {
              const tempData = [];
              const mapper = {};
              if (route.data == "planning-review") {
                console.log(data);
                for (const value of data.chart) {
                  var text = value["category"];
                  var objData = {};
                  objData[text] = value["value"];
                  var obj = {
                    data: objData,
                    name: value["category"],
                  };
                  tempData.push(obj);
                  console.log("chart value", value);
                }
                this.data = tempData;
                console.log("data", this.data);
              } else if (route.data == "backlog-week") {
                console.log(data);
                var closedData = {
                  name: "Sum Closed",
                };
                var openData = {
                  name: "Sum Open",
                };
                var backlogData = {
                  name: "Sum Backlog",
                };
                closedData["data"] = {};
                openData["data"] = {};
                backlogData["data"] = {};
                for (const value of data[0].detail) {
                  console.log("value", value);
                  closedData["data"][value["week"]] =
                    value["detail"]["sumclosed"];
                  openData["data"][value["week"]] = value["detail"]["sumopen"];
                  backlogData["data"][value["week"]] =
                    value["detail"]["sumbacklog"];
                }
                tempData.push(closedData);
                tempData.push(openData);
                tempData.push(backlogData);
                this.data = tempData;
                console.log("chart data", this.data);
              } else if (route.data == "finished-ontime") {
                console.log(data);
                var sumData = {
                  name: "Sum",
                  data: {},
                };
                var countData = {
                  name: "Count",
                  data: {},
                };
                var accData = {
                  name: "Accuracy",
                  data: {},
                };
                var seriesData = {
                  name: "% OnTime",
                  data: {
                    Total: [],
                  },
                };
                for (const value of data[0].chart) {
                  console.log("value", value);
                  sumData["data"][value["week"]] = value["detail"]["sumontime"];
                  countData["data"][value["week"]] =
                    value["detail"]["countontime"];
                  accData["data"][value["week"]] =
                    value["detail"]["accuracy"] * 100;
                }
                tempData.push(sumData);
                tempData.push(countData);
                tempData.push(accData);
                this.data = tempData;

                for (const valueSeries of data[1].chart) {
                  console.log("value", valueSeries);
                  seriesData["data"]["Total"] = valueSeries["accuracy"] * 100;
                  this.onTimeData.push(seriesData);
                }
                console.log("chart data", this.data);
                console.log("on time data", this.onTimeData);
              } else if (route.data == "maintenance-cost-budget") {
                var budgetData = {
                  name: "B2022",
                  data: {},
                };
                var costData = {
                  name: "A2022",
                  data: {},
                };
                var partCostData = {
                  name: "Sp_part2022",
                  data: {},
                };
                var serviceCostData = {
                  name: "Servises2022",
                  data: {},
                };
                var ytdData = {
                  name: "% Ytd",
                  data: {},
                };

                const temp = data["chart"];
                for (const value of temp) {
                  partCostData["data"][value["month"]] =
                    value["detail"]["partcost"];
                  serviceCostData["data"][value["month"]] =
                    value["detail"]["servicecost"];
                  budgetData["data"][value["month"]] =
                    value["detail"]["budget"];
                  costData["data"][value["month"]] = value["detail"]["cost"];
                  ytdData["data"][value["month"]] =
                    value["detail"]["ytd"] * 100;
                  // console.log(costData);
                  // console.log(value);
                }
                console.log("cost data", costData);
                tempData.push(partCostData);
                tempData.push(serviceCostData);
                tempData.push(budgetData);
                tempData.push(costData);
                tempData.push(ytdData);

                this.data = tempData;
              } else if (route.data == "category-failure") {
                var supplyFailure = {
                  name: "Supply Failure",
                  data: {},
                };
                var techFailure = {
                  name: "Tech Failure",
                  data: {},
                };
                var totalData = {
                  name: "Total",
                  data: {},
                };

                const temp = data;
                console.log(temp);
                for (const value of temp) {
                  console.log(value);
                  supplyFailure["data"][value["year"]] =
                    value["detail"][0]["value"];
                  techFailure["data"][value["year"]] =
                    value["detail"][1]["value"];
                  totalData["data"][value["year"]] = value["total"];
                }
                tempData.push(supplyFailure);
                tempData.push(techFailure);
                tempData.push(totalData);

                this.data = tempData;
              } else if (
                route.data == "area-failure" ||
                route.data == "weekly-downtime" ||
                route.data == "daily-downtime"
              ) {
                var mixingData = {
                  name: "Mixing",
                  data: {},
                };
                var dryerData = {
                  name: "Dryer",
                  data: {},
                };
                var PWDRData = {
                  name: "PWDR",
                  data: {},
                };
                var packagingData = {
                  name: "Packaging",
                  data: {},
                };
                var utilitiesData = {
                  name: "Utilities",
                  data: {},
                };
                var totalData2 = {
                  name: "Total",
                  data: {},
                };

                if (route.data == "area-failure") {
                  const temp = data;
                  console.log(temp);
                  for (const value of temp) {
                    mixingData["data"][value["year"]] =
                      value["detail"][0]["value"];
                    dryerData["data"][value["year"]] =
                      value["detail"][1]["value"];
                    PWDRData["data"][value["year"]] =
                      value["detail"][2]["value"];
                    packagingData["data"][value["year"]] =
                      value["detail"][3]["value"];
                    utilitiesData["data"][value["year"]] =
                      value["detail"][4]["value"];
                    totalData2["data"][value["year"]] = value["total"];
                  }
                  tempData.push(mixingData);
                  tempData.push(dryerData);
                  tempData.push(PWDRData);
                  tempData.push(packagingData);
                  tempData.push(utilitiesData);
                  tempData.push(totalData2);

                  this.data = tempData;
                }
                if (route.data == "weekly-downtime") {
                  const temp = data;
                  console.log(temp);
                  for (const value of temp) {
                    mixingData["data"][value["week"]] =
                      value["detail"][0]["value"];
                    dryerData["data"][value["week"]] =
                      value["detail"][1]["value"];
                    PWDRData["data"][value["week"]] =
                      value["detail"][2]["value"];
                    packagingData["data"][value["week"]] =
                      value["detail"][3]["value"];
                    utilitiesData["data"][value["week"]] =
                      value["detail"][4]["value"];
                    totalData2["data"][value["week"]] = value["total"];
                  }
                  tempData.push(mixingData);
                  tempData.push(dryerData);
                  tempData.push(PWDRData);
                  tempData.push(packagingData);
                  tempData.push(utilitiesData);
                  tempData.push(totalData2);

                  this.data = tempData;
                }

                if (route.data == "daily-downtime") {
                  const temp = data;
                  console.log(temp);
                  for (const value of temp) {
                    mixingData["data"][value["day"]] =
                      value["detail"][0]["value"];
                    dryerData["data"][value["day"]] =
                      value["detail"][1]["value"];
                    PWDRData["data"][value["day"]] =
                      value["detail"][2]["value"];
                    packagingData["data"][value["day"]] =
                      value["detail"][3]["value"];
                    utilitiesData["data"][value["day"]] =
                      value["detail"][4]["value"];
                    totalData2["data"][value["day"]] = value["total"];
                  }
                  tempData.push(mixingData);
                  tempData.push(dryerData);
                  tempData.push(PWDRData);
                  tempData.push(packagingData);
                  tempData.push(utilitiesData);
                  tempData.push(totalData2);

                  this.data = tempData;
                }
              } else {
                data.forEach((object) => {
                  var objectValuesInt = 0;
                  if (route.data == "weekly-downtime") objectValuesInt = 1;
                  const year = Object.values(object)[objectValuesInt];

                  var objectInt = 0;
                  for (var i = 0; i < Object.values(object).length; i++) {
                    if (!Array.isArray(Object.values(object)[objectInt])) {
                      objectInt++;
                    }
                  }

                  console.log(Object.values(object));

                  Object.values(object)[objectInt].forEach((tower) => {
                    var value = Object.values(tower)[0];
                    var yearValue = Object.values(tower)[1];

                    if (route.data == "daily-downtime") {
                      yearValue = Object.values(tower)[2];
                    }

                    if (mapper[value] == undefined) {
                      mapper[value] = Object.keys(mapper).length;
                      console.log("value", value);

                      tempData.push({
                        name: value,
                        data: {
                          [year]: yearValue,
                        },
                      });
                    } else {
                      tempData[mapper[value]].data[year] = yearValue;
                    }
                  });
                });
              }
              this.data = tempData;
            }
          } else {
            this.data = resData.data.data;
            this.itemsTable = [];
            this.headersTable = [
              { text: "Name/Unplanned DT", value: "name", align: "start" },
              { text: "Last DT", value: "previous", align: "center" },
              { text: "Day noDT", value: "day", align: "center" },
              { text: "Status DT", value: "status", align: "center" },
            ];
            for (var i = 0; i < this.data.length; i++) {
              var value = this.data[i];
              var item = {
                name: value["name"],
              };
              for (var j = 0; j < value["detail"].length; j++) {
                var subData = value["detail"][j];
                item[subData["category"]] = subData["value"];
              }
              this.itemsTable.push(item);
            }
          }
          if (
            route.data == "breakdown-stopped" ||
            route.data == "primary-stopped"
          ) {
            this.lineData = this.mergeData(this.data);
            this.lineData = {
              name: "Total",
              data: this.lineData,
              type: "line",
            };
            this.data.push(this.lineData);
            var lineSeries = this.data.length - 1;
            this.options["series"] = {
              [lineSeries]: {
                type: "line",
              },
            };
          }

          if (route.data == "topten-downtime") {
            this.options["series"] = {
              1: {
                type: "line",
              },
            };
          }

          if (route.data == "finished-ontime") {
            this.options["series"] = {
              2: {
                type: "line",
              },
            };
          }
        }
      } catch (err) {
        this.chartData = [];
        this.onTimeData = [];
        this.data = [];
        this.headersTable = [];
        this.itemsTable = [];
        // var error = err;
        console.log(err);
        // if (err.response.data.message) {
        //   error = err.response.data.message;
        // }
        // EventBus.$emit("showSnackbar", error, "red");
      }
      // console.log("chart data", this.options.series[4]);

      // console.log("chart data", this.data);
      EventBus.$emit("stopLoading");
    },

    async loadComment() {
      try {
        const response = await axios.get(`${this.$api}/chart/comments/7`);
        const data = response.data.data.comments;
        const length_data = response.data.data.last_page;
        this.paginationLength = length_data;

        data.forEach((comment) => {
          this.userComments.push({
            comments: comment.comment,
            userName: comment.user,
            createdAt: comment.created_at,
          });
        });
      } catch {
        console.log("ra iso");
      }
    },

    async add() {
      try {
        await axios.post(
          `${this.$api}/chart/comments/7?txtcomment=${this.form.txtComment}`
        );
        this.form.txtComment = "";
        this.onChangePagination(this.paginationLength);
      } catch (err) {
        console.log(err);
      }
    },

    async onChangePagination(number) {
      if (number) {
        try {
          const response = await axios.get(
            `${this.$api}/chart/comments/7?page=${number}`
          );
          this.userComments.length = 0;
          const data = response.data.data.comments;

          data.forEach((comment) => {
            this.userComments.push({
              comments: comment.comment,
              userName: comment.user,
              createdAt: comment.created_at,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
.chart-height {
  height: 60vh;
  overflow: auto;
}

rect[stroke-opacity] {
  stroke-width: 0 !important;
}
.status {
  border-radius: 4px;
  width: min-content;
  padding: 4px 8px;
}

.Controlable {
  background: #e5e7eb;
  border: 1px solid #9ca3af;
}

.Monitoring {
  background: #fef9c3;
  border: 1px solid #f59e0b;
}

.shadow {
  box-shadow: 0px 4px 15px rgba(138, 138, 138, 0.1);
}

.smol-img {
  height: 3rem;
  width: 2.8rem;
}

.weight-600 {
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
::v-deep rect[stroke-opacity] {
  stroke-width: 0px !important;
}

::v-deep th {
  background-color: #eb5353 !important;
  color: white !important;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
</style>

<style>
/* .chartWrapper {
  position: relative !important;
}

#chart {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  pointer-events: none !important;
}

.chartAreaWrapper {
  width: 15000px !important;
  overflow-x: scroll !important;
} */
</style>
