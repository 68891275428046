<template>
  <div class="item-menu">
    <div style="position: absolute; width: 100%; height: 100%">
      <div style="width: 100%; height: 100%">
        <v-row style="width: 200px;">
          <v-col cols="2">
            <v-icon class="absolute-center" color="primary" size="40">{{
              content.vcicon == "null" || content.vcicon == ""
                ? "pe-7s-help1"
                : content.vcicon
            }}</v-icon>
          </v-col>
          <v-col cols="10" align-self="center">
            <div style="color: #D1D5DB">{{ content.vcname }}</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainMenuItem",
  props: ["content"],
};
</script>

<style scoped>
.item-menu {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
</style>