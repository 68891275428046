<template>
  <h1>Initial View</h1>
</template>

<script>
export default {
  name: "InitialView",
};
</script>

<style></style>
