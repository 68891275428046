<template>
  <div>
    <Navbar />
    <v-row class="px-10">
      <v-col cols="2">
        <MainMenu />
      </v-col>
      <v-col cols="10">
        <router-view></router-view>
      </v-col>
    </v-row>
    <BottomComponent :class="hideMenu ? 'hide' : ''" />
  </div>
</template>

<script>
import Navbar from "../../core/Navbar.vue";
import BottomComponent from "../../components/home/BottomComponent.vue";
import MainMenu from "./MainMenu.vue";
import EventBus from "../../event-bus";

export default {
  name: "Home",
  components: {
    Navbar,
    BottomComponent,
    MainMenu,
  },
  data: () => ({
    hideMenu: false,
  }),
  created() {
    EventBus.$on("setHideMenu", (value) => (this.hideMenu = value));
  },
  mounted() {
    EventBus.$emit("triggerDefaultMethod");
  }
};
</script>