<template>
  <div style="width: 90%" class="mx-auto my-16" v-if="data != ''">
    <v-row
      no-gutters
      justify="space-between"
      v-for="item in data.data[0]['menu-default']"
      :key="item.id"
      class="mt-10"
    >
      <router-link :to="item.vcurl != '/home' ? item.vcurl : '/'">
        <main-menu-item :content="item" />
      </router-link>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import MainMenuItem from "../../components/home/MainMenuItem.vue";
export default {
  name: "MainMenu",
  components: {
    MainMenuItem,
  },
  data: () => ({
    data: "",
  }),
  mounted() {
    this.data = JSON.parse(localStorage.getItem("data"));
  },
  created() {
    this.$watch(
      () => {
        this.$route;
        return {};
      },
      () => {
        if (this.$route.name != "ChartData") {
          EventBus.$emit("changeTitle", "");
        }
      }
    );
  },
};
</script>