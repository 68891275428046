<template>
  <div>
    <p>speedometerr</p>
    <vue-speedometer
      :width="460"
      :maxSegmentLabels="11"
      :segments="11"
      :value="data"
      :minValue="-50"
      :maxValue="50"
      :segmentColors="['#EB7853']"
      needleColor="#000080"
      :currentValueText="'${value}%'"
      textColor="${textColor}"
    />
  </div>
</template>

<script>
import VueSpeedometer from "vue-speedometer";

export default {
  name: "ChartGauge",
  components: {
    VueSpeedometer,
  },
  props: ["data"],
  data: () => ({
    value: 50,
  }),
};
</script>
