import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ccNames: [],
    pics: [],
    towers: [],
    titles: [],
  },
  mutations: {
    setCcNames(state, payload) {
      state.ccNames = payload
    },
    setPics(state, payload) {
      state.pics = payload
    },
    setTowers(state, payload) {
      state.towers = payload
    },
    setTitles(state, payload) {
      state.titles = payload
    },
  },
  actions: {
    setCcNames({commit}, payload) {
      commit('setCcNames', payload)
    },
    setPics({commit}, payload) {
      commit('setPics', payload)
    },
    setTowers({commit}, payload) {
      commit('setTowers', payload)
    },
    setTitles({commit}, payload) {
      commit('setTitles', payload)
    },
  },
  modules: {
  }
})
