<template>
  <div>
    <v-row>
      <v-col :cols="!hasFilterProp ? '12' : '10'">
        <div
          class="d-flex flex-row align-center justify-center primary--text font-weight-medium"
        >
          <p>{{ subtitle }}</p>
        </div>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="2">
              <v-card height="400" elevation="0" class="card-container d-flex">
                <v-card-title class="card-title text-center pb-0 text-wrap"
                  >Notification<br />Registered
                </v-card-title>
                <div
                  class="d-flex flex-column align-center justify-center flex-grow-1 px-3"
                >
                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-1 mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg yellow-card"
                  >
                    <v-card-title>
                      {{
                        this.chartData["Notification Registered"]["notif_reg"]
                      }}
                    </v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <div id="line-4"></div>
            <div id="line-5"></div>
            <div id="line-2"></div>
            <div id="line-3"></div>
            <v-col cols="2">
              <v-card height="400" elevation="0" class="card-container d-flex">
                <div
                  class="d-flex flex-column align-center justify-center card-title"
                >
                  <v-card-title class="pb-0 card-title"
                    >Gate<br />Keeping</v-card-title
                  >
                </div>
                <div
                  class="d-flex flex-column align-center justify-center flex-grow-1 px-3 px-3"
                >
                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg emerald-card"
                  >
                    <v-card-title>Deleted</v-card-title>
                  </v-card>
                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg emerald-card"
                  >
                    <v-card-title>{{
                      this.chartData["Notification Registered"]["notif_remind"]
                    }}</v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card height="400" elevation="0" class="card-container d-flex">
                <div
                  class="d-flex flex-column align-center justify-center card-title"
                >
                  <v-card-title class="pb-0 card-title"
                    >Order<br />Distribution</v-card-title
                  >
                </div>
                <div
                  class="d-flex flex-column align-center justify-center flex-grow-1 px-3 px-3"
                >
                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg blue-card"
                  >
                    <v-card-title>{{
                      this.chartData["Notification Registered"]["notif_delete"]
                    }}</v-card-title>
                  </v-card>
                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg blue-card"
                  >
                    <v-card-title>{{
                      this.chartData["Notification Registered"][
                        "incoming_order"
                      ]
                    }}</v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card height="400" elevation="0" class="card-container d-flex">
                <div
                  class="d-flex flex-column align-center justify-center card-title"
                >
                  <v-card-title class="pb-0 card-title"
                    >Closed<br />Order</v-card-title
                  >
                </div>
                <div
                  class="d-flex flex-column align-center justify-center flex-grow-1 px-3 px-3"
                >
                  <div
                    class="mx-auto mb-6 mt-4 d-flex align-center justify-center rounded-lg"
                  >
                    <h4>
                      {{
                        Math.floor(
                          this.chartData["Notification Registered"][
                            "percentage_close"
                          ] * 100
                        )
                      }}%
                    </h4>
                  </div>
                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mt-6 mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg orange-card"
                  >
                    <v-card-title>{{
                      this.chartData["Notification Registered"]["notif_close"]
                    }}</v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card height="400" elevation="0" class="card-container d-flex">
                <div
                  class="d-flex flex-column align-center justify-center card-title"
                >
                  <v-card-title class="pb-0 card-title"
                    >Open<br />Order</v-card-title
                  >
                </div>
                <div
                  class="d-flex flex-column align-center justify-center flex-grow-1 px-3 px-3"
                >
                  <div
                    class="mx-auto mb-6 mt-4 d-flex align-center justify-center rounded-lg"
                  >
                    <h4>
                      {{
                        Math.floor(
                          this.chartData["Notification Registered"][
                            "percentage_open"
                          ] * 100
                        )
                      }}%
                    </h4>
                  </div>

                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mt-6 mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg orange-card"
                  >
                    <v-card-title>{{
                      this.chartData["Notification Registered"]["notif_open"]
                    }}</v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-col>

            <v-col cols="2">
              <v-card height="400" elevation="0" class="card-container d-flex">
                <div
                  class="d-flex flex-column align-center justify-center card-title"
                >
                  <v-card-title class="pb-0 card-title"
                    >2WBacklog<br />Order</v-card-title
                  >
                </div>
                <div
                  class="d-flex flex-column align-center justify-center flex-grow-1 px-3"
                >
                  <div
                    class="mx-auto mb-6 mt-4 d-flex align-center justify-center rounded-lg"
                  >
                    <h4>
                      {{
                        Math.floor(
                          this.chartData["Notification Registered"][
                            "percentage_backlog"
                          ] * 100
                        )
                      }}%
                    </h4>
                  </div>

                  <v-card
                    height="62"
                    width="152"
                    elevation="0"
                    class="mx-auto mt-6 mb-6 px-2 py-2 d-flex align-center justify-center rounded-lg orange-card"
                  >
                    <v-card-title>{{
                      this.chartData["Notification Registered"]["notif_backlog"]
                    }}</v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <div id="line-1"></div>
            <v-col cols="2">
              <div class="d-flex flex-row">
                <v-card
                  height="105"
                  width="152"
                  elevation="0"
                  class="mx-auto px-2 py-2 d-flex align-center justify-center rounded-lg red-card"
                >
                  <div class="d-flex flex-column align-center justify-center">
                    <v-card-title class="py-0 for-safety text-center"
                      >For Safety,<br />Legal,<br />Quality</v-card-title
                    >
                  </div>
                </v-card>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-row">
                <v-card
                  height="105"
                  width="152"
                  elevation="0"
                  class="mx-auto px-2 py-2 d-flex align-center justify-center rounded-lg emerald-card"
                >
                  <v-card-title>{{
                    this.chartData["For Safety, Legal, Quality"]["notif_reg"]
                  }}</v-card-title>
                </v-card>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-row">
                <v-card
                  height="105"
                  width="152"
                  elevation="0"
                  class="mx-auto px-2 py-2 d-flex align-center justify-center rounded-lg blue-card"
                >
                  <v-card-title>{{
                    this.chartData["For Safety, Legal, Quality"][
                      "incoming_order"
                    ]
                  }}</v-card-title>
                </v-card>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-row">
                <v-card
                  height="105"
                  width="152"
                  elevation="0"
                  class="mx-auto px-2 py-2 d-flex align-center justify-center rounded-lg orange-card"
                >
                  <v-card-title>{{
                    this.chartData["For Safety, Legal, Quality"]["notif_close"]
                  }}</v-card-title>
                </v-card>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-row">
                <v-card
                  height="105"
                  width="152"
                  elevation="0"
                  class="mx-auto px-2 py-2 d-flex align-center justify-center rounded-lg orange-card"
                >
                  <v-card-title>{{
                    this.chartData["For Safety, Legal, Quality"]["notif_open"]
                  }}</v-card-title>
                </v-card>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-row">
                <v-card
                  height="105"
                  width="152"
                  elevation="0"
                  class="mx-auto px-2 py-2 d-flex align-center justify-center rounded-lg orange-card"
                >
                  <v-card-title>{{
                    this.chartData["For Safety, Legal, Quality"][
                      "notif_backlog"
                    ]
                  }}</v-card-title>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        v-if="!hasFilterProp"
        cols="2"
        align-self="start"
        class="mt-10 comment-position"
      >
        <v-btn
          @click.stop="drawer = !drawer"
          color="primary"
          class="text-none"
          style="border-radius: 10px"
          elevation="0"
          >Comment<v-icon right dark> mdi-comment </v-icon></v-btn
        >

        <div class="mt-5">Year:</div>
        <v-select
          v-if="year"
          :items="years"
          v-model="selectedYear"
          label="Year"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>

        <div>PIC:</div>
        <v-select
          v-if="pics"
          :items="pics"
          item-text="vcname"
          item-value="vcname"
          v-model="selectedPic"
          label="PIC"
          solo
          @change="changeData()"
          background-color="#F6F6FB"
          flat
          dense
        ></v-select>
      </v-col>
      <v-navigation-drawer
        width="324"
        v-if="!hasFilterProp"
        v-model="drawer"
        right
        absolute
        temporary
      >
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-row">
              <v-list-item-title class="weight-600"
                >Comment Rooms</v-list-item-title
              >

              <v-btn icon @click="closeDrawer">
                <v-icon> mdi-window-close </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list dense>
          <v-list-item-content>
            <div
              class="d-flex flex-row"
              v-for="(comment, i) in userComments"
              :key="i"
            >
              <img src="@/assets/user.png" class="smol-img ml-4" />
              <div class="d-flex flex-column ml-4">
                <p class="mb-2 weight-600">{{ comment.userName }}</p>
                <p>{{ comment.createdAt }}</p>
                <p>{{ comment.comments }}</p>
              </div>
            </div>
          </v-list-item-content>
        </v-list>

        <template v-slot:append>
          <div class="text-center">
            <v-pagination
              @input="onChangePagination"
              :total-visible="5"
              v-model="paginationCounter"
              :length="paginationLength"
            ></v-pagination>
          </div>

          <div class="pa-2">
            <v-text-field
              v-model="form.txtComment"
              @click:append="add"
              append-icon="mdi-send"
              label="Type Your Comment"
              outlined
            >
            </v-text-field>
          </div>
        </template>
      </v-navigation-drawer>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "../../event-bus";

export default {
  props: [
    "routeProp",
    "towerProp",
    "yearProp",
    "monthProp",
    "picProp",
    "logProp",
    "ccProp",
    "titleProp",
    "hasFilterProp",
  ],
  data: () => ({
    paginationCounter: 1,
    paginationLength: 1,
    year: true,
    pic: true,
    drawer: null,
    userComments: [],
    form: {
      txtComment: "",
    },

    pics: [],
    years: [],
    selectedPic: 1,
    selectedYear: 2022,
    chartData: [],
    subtitle: "",
    route: "",
    apiRequestTimer: null,
  }),

  async created() {
    var d = new Date();
    this.selectedTower = 0;
    this.selectedYear = d.getFullYear();

    this.$watch(
      () => {
        this.$route;
        return {};
      },
      async () => {
        this.route = this.$route.params;
        if (this.routeProp) {
          this.route = this.routeProp;
        }
        this.setTitle();
        this.loaded = true;
        for (var i = 2014; i <= 2023; i++) {
          this.years.push(i);
        }
        var d = new Date();
        this.selectedYear = d.getFullYear();
        this.years.reverse();
        await this.setData();
        if (!this.hasFilterProp) {
          await this.getListZones();
          await this.loadComment();
        }
      }
    );
  },
  async mounted() {
    this.apiRequestTimer = setTimeout(async () => {
      this.route = this.$route.params;
      if (this.routeProp) {
        this.route = this.routeProp;
      }
      this.loaded = true;
      for (var i = 2014; i <= 2023; i++) {
        this.years.push(i);
      }
      this.years.reverse();
      var d = new Date();
      this.selectedYear = d.getFullYear();
      await this.getListZones();
      await this.getData();
      await this.loadComment();
      await this.setData();
    }, 1000);
  },

  beforeDestroy() {
    clearTimeout(this.apiRequestTimer);
  },

  methods: {
    setTitle() {
      var route = this.route;
      var localStorageData = JSON.parse(localStorage.getItem("data"));
      localStorageData = localStorageData["data"][1]["menu-content"].find(
        (e) => e.vcurl == `/chart/${route.menu}`
      );
      localStorageData = localStorageData["childrens"].find(
        (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}`
      );

      this.title = localStorageData.vcname;
      EventBus.$emit("changeTitle", this.title);

      localStorageData = localStorageData["childrens"].find(
        (e) => e.vcurl == `/chart/${route.menu}/${route.subMenu}/${route.data}`
      );

      this.subtitle = localStorageData.vcname;
    },
    changeData() {
      this.getData();
    },

    async getData() {
      EventBus.$emit("startLoading");
      try {
        var route = this.route;
        this.setTitle();
        if (this.hasFilterProp) {
          this.selectedYear = this.yearProp;
          this.selectedPic = this.picProp;
        }
        const params = {
          year: this.selectedYear,
          pic: this.selectedPic == 'All PIC' ? undefined : this.selectedPic,
        };

        const res = await axios.get(
          `${this.$api}/chart/${route.menu}/${route.subMenu}/${route.data}`,
          { params }
        );
        const data = res.data.data;
        console.log("data here", data);
        this.chartData = data;
        console.log(this.chartData);
      } catch (err) {
        var error = err;
        console.log(err);
        if (err.response.data.message) {
          error = err.response.data.message;
        }
        EventBus.$emit("showSnackbar", error, "red");
      }
      EventBus.$emit("stopLoading");
    },

    async getListZones() {
      this.pics = JSON.parse(localStorage.getItem("pics"));
    },

    async loadComment() {
      try {
        const response = await axios.get(`${this.$api}/chart/comments/7`);
        const data = response.data.data.comments;
        const length_data = response.data.data.last_page;
        this.paginationLength = length_data;
        data.forEach((comment) => {
          this.userComments.push({
            comments: comment.comment,
            userName: comment.user,
            createdAt: comment.created_at,
          });
        });
      } catch (err) {
        console.log(err);
      }
    },

    async add() {
      try {
        await axios.post(
          `${this.$api}/chart/comments/7?txtcomment=${this.form.txtComment}`
        );
        this.form.txtComment = "";
        this.onChangePagination(this.paginationLength);
      } catch (err) {
        console.log(err);
      }
    },

    async onChangePagination(number) {
      if (number) {
        try {
          const response = await axios.get(
            `${this.$api}/chart/comments/7?page=${number}`
          );
          this.userComments.length = 0;
          const data = response.data.data.comments;

          data.forEach((comment) => {
            this.userComments.push({
              comments: comment.comment,
              userName: comment.user,
              createdAt: comment.created_at,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
rect[stroke-opacity] {
  stroke-width: 0 !important;
}
.status {
  border-radius: 4px;
  width: min-content;
  padding: 4px 8px;
}

.Controlable {
  background: #e5e7eb;
  border: 1px solid #9ca3af;
}

.Monitoring {
  background: #fef9c3;
  border: 1px solid #f59e0b;
}

.shadow {
  box-shadow: 0px 4px 15px rgba(138, 138, 138, 0.1);
}

.smol-img {
  height: 3rem;
  width: 2.8rem;
}

.weight-600 {
  font-weight: 600;
}
</style>

<style scoped>
.v-card__title {
  font-size: 1em !important;
  justify-content: center !important;
}
.card-container {
  display: flex !important;
  flex-direction: column !important;
  background: #f9fafb;
}

.yellow-card {
  background: #fef3c7 !important;
  border: 1px solid #f59e0b !important;
  color: #fb923c;
  z-index: 2;
}

.emerald-card {
  background: #f0fdf4;
  border: 1px solid #10b981;
  color: #10b981;
  z-index: 1;
}

.blue-card {
  background: #e0f2fe;
  border: 1px solid #0891b2;
  color: #0891b2;
  z-index: 1;
}

.orange-card {
  background: #feeedd;
  border: 1px solid #eb7853;
  color: #eb7853;
  z-index: 1;
}

.red-card {
  background: #fee2dd;
  border: 1px solid #dc2626;
  color: #dc2626;
  z-index: 1;
}

.card-title {
  font-weight: 600 !important;
  text-align: center !important;
}

#line-1 {
  font-size: 0;
  width: 89%;
  height: 2.4px;
  margin-left: 5rem;
  color: #fff;
  background-color: #000;
  position: absolute;
  bottom: 4rem;
}

#line-2 {
  font-size: 0;
  width: 67%;
  left: 16rem;
  height: 2px;
  margin-left: 2rem;
  color: #fff;
  background-color: #000;
  position: absolute;
  top: 18rem;
  z-index: 1;
}

#line-3 {
  font-size: 0;
  width: 17%;
  left: 16rem;
  height: 2px;
  margin-left: 2rem;
  color: #fff;
  background-color: #000;
  position: absolute;
  top: 12.5rem;
  z-index: 1;
}

#line-4 {
  font-size: 0;
  width: 7rem;
  height: 2px;
  left: 3.5rem;
  margin-left: 2rem;
  color: #fff;
  transform: rotate(-35deg);
  background-color: #000;
  position: absolute;
  top: 15rem;
  z-index: 1;
}

#line-5 {
  font-size: 0;
  width: 7rem;
  height: 2px;
  left: 3.5rem;
  margin-left: 2rem;
  color: #fff;
  transform: rotate(35deg);
  background-color: #000;
  position: absolute;
  top: 15.3rem;
  z-index: 1;
}

@media only screen and (min-width: 1904px) {
  .v-card__title {
    font-size: 1.2em !important;
  }
}

@media only screen and (min-width: 1500px) {
  #line-4 {
    width: 8rem;
    top: 14.5rem;
  }

  #line-5 {
    width: 8rem;
    top: 15.6rem;
  }
}

@media only screen and (min-width: 1600px) {
  #line-4 {
    width: 7.9rem;
    top: 14.8rem;
    left: 4.2rem;
  }

  #line-5 {
    width: 7.9rem;
    top: 15.6rem;
    left: 4.2rem;
  }
}

@media only screen and (min-width: 1730px) {
  #line-4 {
    width: 10rem;
    top: 14.2rem;
    left: 4.2rem;
  }

  #line-5 {
    width: 10rem;
    top: 16.2rem;
    left: 4.2rem;
  }
}

@media only screen and (min-width: 1800px) {
  #line-4 {
    width: 9rem;
    top: 13.6rem;
    left: 7rem;
  }

  #line-5 {
    width: 9rem;
    top: 16.6rem;
    left: 7rem;
  }
}
</style>

<style>
.for-safety {
  font-weight: 400;
}
</style>
