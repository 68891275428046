<template>
  <v-app class="py-16 px-6">
    <div>
      <v-data-table :headers="headers" :items="data" class="mx-16 mt-6">
        <template v-slot:top>
          <v-row class="mb-6 mx-0" justify="end">
            <v-dialog
              @input="(v) => v || emptyPowerOut()"
              v-model="dialog"
              width="850px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary radius"
                  height="42"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog()"
                >
                  <v-icon class="mr-2" size="18">mdi-plus</v-icon>
                  Add Data
                </v-btn>
              </template>

              <v-card>
                <v-form ref="form" v-on:submit.prevent>
                  <v-card-title
                    class="d-flex flex-row justify-space-between align-center sticky-bar"
                  >
                    <span class="text-h5 pl-3 pt-6 span-class">
                      {{ isEditing ? "Edit Data" : "Add Data" }} {{ formTitle }}
                    </span>

                    <v-btn
                      color="primary mt-7"
                      width="30%"
                      @click="isEditing ? submitData(true) : submitData(false)"
                    >
                      {{ isEditing ? "Edit Data" : "Add Data" }}
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row class="px-3">
                        <v-col cols="12" v-for="(model, i) in form" :key="i">
                          <h2
                            class="ps-3 py-1 pb-2"
                            v-if="
                              model.type !== 'number' &&
                              model.label !== 'Category' &&
                              model.label !== 'Zone' &&
                              model.label !== 'Start' &&
                              model.label !== 'Finish' &&
                              model.label !== 'Noted' &&
                              model.label !== 'Floc' &&
                              model.label !== 'Equipment' &&
                              model.label !== 'Power Out' &&
                              model.label !== 'Component'
                            "
                          >
                            {{ model.label }}
                          </h2>

                          <v-select
                            v-model="models[model.name]"
                            filled
                            flat
                            dense
                            v-if="model.name == 'inttype'"
                            :items="listTypes"
                            item-text="vcname"
                            item-value="id"
                            return-object
                          ></v-select>
                          <v-select
                            v-model="models[model.name]"
                            filled
                            flat
                            dense
                            v-if="model.name == 'inttower'"
                            :items="listTowers"
                            item-text="vcname"
                            @change="checkFloc()"
                            item-value="id"
                            return-object
                          ></v-select>

                          <v-text-field
                            v-if="model.type == 'date'"
                            v-model="models[model.name]"
                            type="text"
                            :placeholder="'Input the ' + model.type"
                            :onfocus="checkSubMenu()"
                            onblur="(this.type='text')"
                            solo
                            clearable
                            flat
                            dense
                            background-color="#EFEFEF"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <div class="line my-6 mt-3"></div>

                      <table>
                        <tr>
                          <th>Parameter</th>
                          <th v-if="subMenu != 'loss-registration'">UoM</th>
                          <th>Values</th>
                        </tr>

                        <tr v-for="(model, i) in form" :key="i">
                          <td class="td1">
                            <p
                              class="parameter py-1 ps-3 pb-0"
                              v-if="
                                (model.type === 'number' &&
                                  model.label != 'Power Out') ||
                                model.label === 'Category' ||
                                model.label === 'Zone' ||
                                model.label === 'Start' ||
                                model.label === 'Finish' ||
                                model.label === 'Noted' ||
                                model.label === 'Floc' ||
                                model.label === 'Equipment' ||
                                model.label === 'Component'
                              "
                            >
                              {{ model.label }}
                            </p>
                            <p
                              class="parameter py-1 ps-3 pb-0"
                              v-if="
                                model.label == 'Power Out' && isPowerOutShowed
                              "
                            >
                              {{ model.label }}
                            </p>
                            <div v-else></div>
                          </td>
                          <td v-if="subMenu != 'loss-registration'" class="td2">
                            <p
                              class="type py-1 pb-0"
                              v-if="
                                model.type === 'number' ||
                                model.label === 'Category' ||
                                model.label === 'Zone' ||
                                model.label === 'Start' ||
                                model.label === 'Finish' ||
                                model.label === 'Noted' ||
                                model.label === 'Floc' ||
                                model.label === 'Equipment' ||
                                model.label === 'Power Out' ||
                                model.label === 'Component'
                              "
                            >
                              {{ model.uom }}
                            </p>
                          </td>
                          <td class="td3">
                            <v-datetime-picker
                              :text-field-props="textFieldProps"
                              :timePickerProps="timePickerProps"
                              v-if="model.type == 'datetime'"
                              timeFormat="hh:mm"
                              v-model="models[model.name]"
                              class="py-1 px-2"
                            >
                            </v-datetime-picker>

                            <v-text-field
                              v-if="
                                model.type != 'select' &&
                                model.type != 'datetime' &&
                                model.type != 'date' &&
                                model.label != 'Noted' &&
                                model.label != 'Component' &&
                                model.label != 'Power Out'
                              "
                              class="v-text-field-khusus px-2 me-2 py-3 pb-0 pe-0"
                              v-model="models[model.name]"
                              :type="
                                model.type == 'integer' ? 'number' : model.type
                              "
                              :rules="validateRules(model)"
                              :label="'Input the ' + model.type"
                              solo
                              clearable
                              flat
                              dense
                              background-color="#EFEFEF"
                            ></v-text-field>

                            <v-text-field
                              v-if="
                                model.label == 'Noted' ||
                                model.label == 'Component'
                              "
                              class="px-2 me-2 py-3 pb-0 pe-0"
                              v-model="models[model.name]"
                              :type="
                                model.type == 'integer' ? 'number' : model.type
                              "
                              :rules="validateRules(model)"
                              :label="'Input the ' + model.type"
                              solo
                              clearable
                              flat
                              dense
                              background-color="#EFEFEF"
                            ></v-text-field>

                            <div>
                              <v-text-field
                                v-if="
                                  model.label == 'Power Out' && isPowerOutShowed
                                "
                                class="px-2 me-2 py-3 pb-0 pe-0"
                                v-model="models[model.name]"
                                :type="
                                  model.type == 'integer'
                                    ? 'number'
                                    : model.type
                                "
                                :rules="validateRules(model)"
                                :label="'Input the ' + model.type"
                                solo
                                clearable
                                flat
                                dense
                                background-color="#EFEFEF"
                              ></v-text-field>
                            </div>

                            <v-select
                              v-model="models[model.name]"
                              filled
                              flat
                              dense
                              v-if="model.name == 'intzone'"
                              :items="listZones"
                              @change="checkFloc()"
                              item-text="vcname"
                              item-value="id"
                              return-object
                              class="px-2 me-2 py-3 pb-0 pe-0"
                            ></v-select>
                            <v-select
                              id="categoryForm"
                              v-model="models[model.name]"
                              filled
                              flat
                              dense
                              v-if="model.name == 'intcategory'"
                              :items="listCategories"
                              item-text="vcname"
                              @change="checkCategory()"
                              item-value="id"
                              return-object
                              class="px-2 me-2 py-3 pb-0 pe-0"
                            ></v-select>
                            <v-select
                              v-model="models[model.name]"
                              filled
                              flat
                              dense
                              v-if="model.name == 'intfloc'"
                              :items="listFlocs"
                              @change="checkEquip()"
                              item-text="vcname"
                              item-value="id"
                              return-object
                              class="px-2 me-2 py-3 pb-0 pe-0"
                            ></v-select>
                            <v-select
                              v-model="models[model.name]"
                              filled
                              flat
                              dense
                              v-if="model.name == 'intequipment'"
                              :items="listEquipments"
                              item-text="vcname"
                              item-value="id"
                              return-object
                              class="px-2 me-2 py-3 pb-0 pe-0"
                            ></v-select>
                          </td>
                        </tr>
                      </table>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="openDialog(item.id)">
            mdi-pencil
          </v-icon>
          <v-dialog v-model="dialogDelete" width="400" :retain-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" @click="itemId = item.id">
                mdi-delete
              </v-icon>
            </template>
            <v-card>
              <v-card-title class="text-h5"> Delete Data </v-card-title>

              <v-card-text> Are you sure to delete this data? </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="mx-5"
                  text
                  @click="dialogDelete = false"
                >
                  No
                </v-btn>
                <v-btn color="primary" text @click="deleteData(itemId)">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { EventBus } from "../../event-bus";

export default {
  name: "DataTower",

  data: () => ({
    isPowerOutShowed: false,
    dialog: false,
    dialogDelete: false,
    data: [],
    actualData: [],
    listTowers: [],
    listCategories: [],
    listTypes: [],
    listZones: [],
    listFlocs: [],
    listEquipments: [],
    listPowerOut: [],
    routing: "",
    subMenu: "",
    textFieldProps: {
      filled: true,
      dense: true,
      flat: true,
      borderless: true,
    },
    timePickerProps: {
      format: "24hr",
    },
    result: null,
    isEditing: false,
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    headers: [],
    models: {},
    form: [],
    itemId: "",
  }),

  created() {
    this.$watch(
      () => {
        this.$route;
        return {};
      },
      async () => {
        await this.initData();
      }
    );
  },

  computed: {
    formTitle() {
      return this.humanize(this.$route.params.subMenu);
    },
  },

  async mounted() {
    await this.initData();
    console.log("data", this.data);
  },
  methods: {
    emptyPowerOut() {
      this.isPowerOutShowed = false;
      this.models["intpower_out"] = 0;
    },
    checkCategory() {
      if (this.models["intcategory"]["vcname"].includes("Supply Failure"))
        this.isPowerOutShowed = true;
      else {
        this.emptyPowerOut();
      }
    },
    checkSubMenu() {
      return this.subMenu == "loss-registration"
        ? "(this.type = 'date')"
        : "(this.type = 'month')";
    },
    humanize(str) {
      var i,
        frags = str.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    clearData() {
      this.data = [];
      this.actualData = [];
      this.listTower = [];
      this.listCategories = [];
      this.listTypes = [];
      this.listZones = [];
      this.form = [];
      this.models = {};
      this.headers = [];
    },

    async initData() {
      EventBus.$emit("startLoading");
      this.clearData();
      this.clearForm();
      this.subMenu = this.$route.params.subMenu;

      await this.getListCategories();
      await this.getListTypes();
      await this.getListZones();
      await this.getListTowers();
      await this.loadData();
      await this.loadFormProperties();
      EventBus.$emit("stopLoading");
    },

    async getListTowers() {
      try {
        const res = await axios.get(`${this.$api}/list/towers`);
        const data = res.data.data;
        this.listTowers = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getListZones() {
      try {
        const res = await axios.get(`${this.$api}/list/zones`);
        if (res.status == 404) {
          EventBus.$emit("showSnackbar", "No data available", "red");
        }
        const data = res.data.data;
        this.listZones = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getListCategories() {
      try {
        const res = await axios.get(`${this.$api}/list/categories`);
        if (res.status == 404) {
          EventBus.$emit("showSnackbar", "No data available", "red");
        }
        const categories = res.data.data;
        this.listCategories = categories;
      } catch (error) {
        console.log(error);
      }
    },

    async getListFlocs(zone, tower) {
      try {
        const res = await axios.get(`${this.$api}/list/flocs`, {
          params: {
            zone: zone,
            tower: tower,
          },
        });
        const data = res.data.data;
        this.listFlocs = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getListEquipments(zone, tower, floc) {
      try {
        const res = await axios.get(`${this.$api}/list/equipments`, {
          params: {
            zone: zone,
            tower: tower,
            floc: floc,
          },
        });
        if (res.status == 404) {
          EventBus.$emit("showSnackbar", "No data available", "red");
        }
        const data = res.data.data;
        this.listEquipments = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getListTypes() {
      try {
        const res = await axios.get(`${this.$api}/list/types`);
        if (res.status == 404) {
          EventBus.$emit("showSnackbar", "No data available", "red");
        }
        const types = res.data.data;
        this.listTypes = types;
      } catch (error) {
        console.log(error);
      }
    },

    async checkFloc() {
      EventBus.$emit("startLoading");
      try {
        console.log("models", this.models);
        const res = await this.getListFlocs(
          this.models["intzone"]["id"],
          this.models["inttower"]["id"]
        );
        if (res.status == 404) {
          EventBus.$emit("showSnackbar", "No data available", "red");
        }
        this.listEquipments = [];
      } catch (e) {
        console.log(e);
      }
      EventBus.$emit("stopLoading");
    },

    async checkEquip() {
      const route = this.$route.params;
      // console.log("test");
      EventBus.$emit("startLoading");
      try {
        if (route.subMenu == "loss-registration") {
          await this.getListEquipments(
            this.models["intzone"]["id"],
            this.models["inttower"]["id"],
            this.models["intfloc"]["id"]
          );
        }
      } catch (e) {
        // console.log(e);
      }
      EventBus.$emit("stopLoading");
    },

    async loadFormProperties() {
      this.clearForm();
      try {
        const route = this.$route.params;
        const res = await axios.get(
          `${this.$api}/${route.menu}/${route.subMenu}/create`
        );
        const properties = res.data;
        this.form = properties;
      } catch (error) {
        console.log(error);
      }
    },

    clearModels() {
      this.models = {};
      const dateMoment = moment();
      var dtstart = dateMoment.format("YYYY-MM-DD hh:mm");
      var dtfinish = dateMoment.format("YYYY-MM-DD hh:mm");
      this.models["dtstart"] = dtstart;
      this.models["dtfinish"] = dtfinish;
      console.log(this.models);
      try {
        this.$refs.form.resetValidation();
      } catch (e) {
        console.log(e);
      }
    },

    clearForm() {
      try {
        this.$refs.form.resetValidation();
      } catch (e) {
        console.log(e);
      }
      this.form = [];
    },

    validateRules(field) {
      if (field.required) {
        return [(v) => !!v || `${field.label} can't be empty`];
      }
    },

    async loadData() {
      try {
        const route = this.$route.params;
        const response = await axios.get(
          `${this.$api}/${route.menu}/${route.subMenu}/all`
        );
        const data = response.data.data;

        // set headers
        if (data.length > 0) {
          for (var i in data[0]) {
            this.headers.push({
              text: i,
              value: i,
              sortable: i == "date" ? false : true,
              align: i == "id" ? "start" : "center",
            });
          }
          this.headers.push({
            text: "actions",
            value: "actions",
            sortable: false,
          });
        }
        this.actualData = data;

        for (var j = 0; j < data.length; j++) {
          var obj;
          if (data[j]["inttower"]) {
            obj = this.listTowers.find((e) => e["id"] == data[j]["inttower"]);
            data[j]["inttower"] = obj ? obj["vcname"] : "Undefined";
          }

          if (data[j]["intcategory"]) {
            obj = this.listCategories.find(
              (e) => e["id"] == data[j]["intcategory"]
            );
            data[j]["intcategory"] = obj ? obj["vcname"] : "Undefined";
          }

          if (data[j]["intzone"]) {
            obj = this.listZones.find((e) => e["id"] == data[j]["intzone"]);
            data[j]["intzone"] = obj ? obj["vcname"] : "Undefined";
          }

          if (data[j]["inttype"]) {
            obj = this.listTypes.find((e) => e["id"] == data[j]["inttype"]);
            data[j]["inttype"] = obj ? obj["vcname"] : "Undefined";
          }
        }
        this.data = data;
      } catch (err) {
        console.log(err);
      }
    },

    async openDialog(itemId) {
      this.clearModels();
      if (itemId) {
        EventBus.$emit("startLoading");
        const route = this.$route.params;
        const response = await axios.get(
          `${this.$api}/${route.menu}/${route.subMenu}/id/${itemId}`
        );

        console.log("get item id response", response);

        const data = response.data;
        var obj;

        data["dtstart"] = moment(
          data["dtstart"],
          "YYYY-MM-DD h:mm:ss"
        ).toDate();
        data["dtfinish"] = moment(
          data["dtfinish"],
          "YYYY-MM-DD h:mm:ss"
        ).toDate();

        try {
          const isWithDate = this.subMenu == "loss-registration";
          const dateConverted = moment(data["dtperiode"]);
          const dateFormatted = isWithDate
            ? dateConverted.format("YYYY-MM-DD")
            : dateConverted.format("YYYY-MM");
          data["dtperiode"] = dateFormatted;
        } catch (e) {
          console.log(e);
        }

        try {
          if (data["inttower"] || data["inttower"] == 0) {
            obj = this.listTowers.find((e) => e["id"] == data["inttower"]);
            data["inttower"] = obj;
          }
        } catch (e) {
          console.log(e);
        }

        // console.log("1");
        try {
          if (data["intcategory"]) {
            obj = this.listCategories.find(
              (e) => e["vcname"] == data["intcategory"]
            );
            data["intcategory"] = obj;
          }
        } catch (e) {
          console.log(e);
        }
        // console.log("2");

        try {
          if (data["intzone"] || data["intzone"] == 0) {
            obj = this.listZones.find((e) => e["vcname"] == data["intzone"]);
            data["intzone"] = obj;
            await this.getListFlocs(
              data["intzone"]["id"],
              data["inttower"]["id"]
            );
          }
        } catch (e) {
          console.log(e);
        }
        // console.log("3");

        try {
          if (data["inttype"]) {
            obj = this.listTypes.find((e) => e["id"] == data["inttype"]);
            data["inttype"] = obj;
          }
        } catch (e) {
          console.log(e);
        }
        // console.log("4");
        console.log(data["intfloc"]);

        try {
          console.log("data floc", data);
          console.log("list flocs", this.listFlocs);
          obj = this.listFlocs.find((e) => e["id"] == data["intfloc"]);
          data["intfloc"] = obj;

          await this.getListEquipments(
            data["intzone"]["id"],
            data["inttower"]["id"],
            data["intfloc"]["id"]
          );
        } catch (e) {
          console.log(e);
        }

        try {
          obj = this.listEquipments.find(
            (e) => e["id"] == data["intequipment"]
          );
          data["intequipment"] = obj;
        } catch (e) {
          console.log(e);
        }
        // console.log("5");

        // console.log(data);

        this.models = data;
        this.dialog = true;
        this.isEditing = true;
        console.log(this.models);
      } else {
        this.isEditing = false;
        this.clearModels();
      }
      this.removeBorderIfClassHasNoChildren("td1");
      this.removeBorderIfClassHasNoChildren("td2");
      this.removeBorderIfClassHasNoChildren("td3");
      EventBus.$emit("stopLoading");
    },

    removeBorderIfClassHasNoChildren(className) {
      var elements = document.getElementsByClassName(className);
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (element.childElementCount == 0) {
          element.remove();
        }
      }
    },

    async submitData(isEdit) {
      EventBus.$emit("startLoading");
      console.log("isEdit", isEdit);
      console.log("this models ", this.models);

      try {
        const obj = this.models;
        const route = this.$route.params;
        const isWithDate = this.subMenu == "loss-registration";

        try {
          const dateConverted = moment(obj["dtperiode"]);
          const dateFormatted = isWithDate
            ? dateConverted.format("YYYY-MM-DD")
            : dateConverted.format("YYYY-MM");
          obj["dtperiode"] = dateFormatted;
        } catch (e) {
          console.log(e);
        }

        if (obj["dtperiode"]) {
          let date = isWithDate ? obj["dtperiode"] : obj["dtperiode"] + "-01";
          obj["dtperiode"] = date;
          // console.log(date);
          console.log(obj);
        }
        if (obj["dtstart"] || obj["dtfinish"]) {
          var dtstart = moment(this.models["dtstart"]).format(
            "YYYY-MM-DD hh:mm:ss"
          );
          var dtfinish = moment(this.models["dtfinish"]).format(
            "YYYY-MM-DD hh:mm:ss"
          );

          if (route.subMenu == "loss-registration") {
            obj["dtstart"] = dtstart;
            obj["dtfinish"] = dtfinish;
            obj["decduration"] = 0;
          }
        }

        try {
          obj["inttype"] = obj["inttype"]["id"];
        } catch (e) {
          console.log(e);
        }
        try {
          obj["inttower"] = obj["inttower"]["id"];
        } catch (e) {
          console.log(e);
        }
        try {
          obj["intzone"] = obj["intzone"]["id"];
        } catch (e) {
          console.log(e);
        }
        try {
          obj["intfloc"] = obj["intfloc"]["id"];
        } catch (e) {
          console.log(e);
        }

        try {
          obj["intequipment"] = obj["intequipment"]["id"];
        } catch (e) {
          console.log(e);
        }

        try {
          obj["intcategory"] = obj["intcategory"]["id"];
        } catch (e) {
          console.log(e);
        }

        if (isEdit) {
          await axios.post(
            `${this.$api}/${route.menu}/${route.subMenu}/update/` +
              this.models.id,
            obj
          );
        } else {
          await axios.post(
            `${this.$api}/${route.menu}/${route.subMenu}/add`,
            obj
          );
        }

        this.dialog = false;
        this.clearModels();
        await this.initData();
      } catch (err) {
        console.log(err);
      }

      EventBus.$emit("stopLoading");
    },

    async deleteData(dataId) {
      EventBus.$emit("startLoading");
      this.dialogDelete = false;
      try {
        const route = this.$route.params;
        await axios.delete(
          `${this.$api}/${route.menu}/${route.subMenu}/delete/` + dataId
        );
        await this.initData();
      } catch (err) {
        console.log(err);
      }
      EventBus.$emit("stopLoading");
    },
  },
};
</script>

<style scoped>
.sticky-bar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
}

.v-text-field-khusus {
  width: 250px !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.radius {
  border-radius: 0.6rem !important;
}

.button-p {
  color: white;
  text-align: center !important;
}

h4,
h2 {
  font-weight: 500;
}

h2 {
  color: black;
}

.span-class {
  font-weight: 600 !important;
}

table {
  border-collapse: collapse !important;
  width: 98% !important;
  margin: auto !important;
}

th {
  background: #eb5353 !important;
  color: white !important;
  padding: 1rem !important;
}

td,
th {
  border: 1px solid #000000 !important;
  width: 33% !important;
  font-weight: 500;
  font-size: 20px;
}

td {
  margin: auto !important;
}

tr:nth-child(even) {
  background-color: #fff !important;
}

.col {
  /* width: 100%; */
  padding: 0px !important;
}

.parameter {
  font-weight: 600;
  font-size: 20px;
}

.type {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
}
</style>

<style lang="scss" scoped>
::v-deep th {
  background-color: #eb5353 !important;
  color: white !important;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
</style>

<style>
.line {
  height: 2px;
  background: #efefef;
  border-radius: 10px;
  width: 98%;
  margin: auto;
}

.v-text-field {
  padding: 0.7rem 0.5rem 0 0.5rem !important;
}

.v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
