<template>
  <div class="bottom-center bottom-container" v-if="data && allContent">
    <v-sheet class="mx-auto" max-width="830">
      <v-slide-group v-model="model" class="pa-4" center-active mandatory>
        <v-slide-item
          v-for="(item, index) in filteredContent"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-card
            elevation="0"
            :color="active ? 'primary' : ''"
            class="ma-4"
            @click="toggle"
            v-on:click="clickItem(item, active, index)"
            :ripple="false"
          >
            <router-link :to="{ path: `${item.vcurl}` }">
              <BottomComponentItem
                :active="active"
                :item="item"
                :id="`item-${item.id}`"
              />
            </router-link>
          </v-card>
        </v-slide-item>
        <template v-slot:next="{}"> </template>
        <template v-slot:prev="{}"> </template>
      </v-slide-group>
    </v-sheet>
    <!-- <div class="d-flex">
      <div v-for="item in filteredContent" :key="item.id" class="mr-10">
        <router-link :to="item.vcurl"
          ><BottomComponentItem :item="item" :id="`item-${item.id}`"
        /></router-link>
      </div>
    </div> -->
  </div>
</template>

<script>
import BottomComponentItem from "./BottomComponentItem.vue";

export default {
  name: "BottomComponent",
  components: {
    BottomComponentItem,
  },
  data: () => ({
    data: "",
    model: 0,
    allContent: [],
    allContentReversed: [],
    filteredContent: [],
    previousItems: [],
    options: {
      position: {
        start: 0,
      },
    },
    defaultItem: {
      id: 0,
      vcname: "Home",
      vcicon: "pe-7s-home",
      vcurl: "/",
      childrens: [],
    },
  }),
  created() {
    this.$watch(
      () => {
        this.model;
        return {};
      },
      () => {}
    );
  },
  methods: {
    checkHomeIcon() {
      if (this.previousItems.length > 0) {
        this.defaultItem.vcname = "Back";
        this.defaultItem.vcicon = "pe-7s-back";
      } else {
        this.defaultItem.vcname = "Home";
        this.defaultItem.vcicon = "pe-7s-home";
      }

      if (this.filteredContent[0].vcurl == "/") {
        this.filteredContent.shift();
        this.filteredContent.unshift(this.defaultItem);
      } else {
        this.filteredContent.unshift(this.defaultItem);
      }
    },
    clickItem(item, active, index) {
      if (active) {
        const allItem = item["childrens"];
        if (allItem.length != 0) {
          this.previousItems.push(this.filteredContent);
          this.filteredContent = allItem;
          var path = item["childrens"][0]['vcurl'];
          if (path == '/') {
            path = item["childrens"][index]['vcurl'];
          }
          this.$router.push({ path: path });
          console.log('item testing',index);
          console.log('item testing',item);
        } else {
          if (item.vcurl == "/" && this.previousItems.length > 0) {
            this.filteredContent =
              this.previousItems[this.previousItems.length - 1];
            this.previousItems.pop();
          } else {
            this.$router.push({ path: item["vcurl"] });
          }
        }
      }

      this.checkHomeIcon();
    },
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem("data"));
    this.data.data[1]["menu-content"].unshift(this.defaultItem);
    this.allContent = this.data.data[1]["menu-content"];

    this.filteredContent.push(...this.allContent);
    this.model = this.allContent.length;

    // console.log(this.filteredContent[1]);
  },
};
</script>

<style scoped>
.bottom-container {
  width: 60%;
  position: sticky;
}

.transparent-bg {
  background: transparent !important;
}

.bottom-center {
  position: absolute;
  left: 52%;
  bottom: 0;
  transform: translate(-50%, 0%);
}
</style>

<style lang="scss" scoped>
::v-deep .v-slide-group__content {
  align-items: center;
}

::v-deep .theme--light.v-sheet {
  background-color: transparent;
  border-color: transparent;
}
</style>
