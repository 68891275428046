var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"py-16 px-6"},[_c('div',[_c('v-data-table',{staticClass:"mx-16 mt-6",attrs:{"headers":_vm.headers,"items":_vm.data},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-6 mx-0",attrs:{"justify":"end"}},[_c('v-dialog',{attrs:{"width":"1050px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary radius","height":"42","dark":""},on:{"click":function($event){return _vm.openDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v("mdi-plus")]),_vm._v(" Add Data ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card-title',{staticClass:"d-flex flex-row justify-space-between align-center sticky-bar"},[_c('span',{staticClass:"text-h5 pl-3 pt-6 span-class"},[_vm._v(" "+_vm._s(_vm.isEditing ? "Edit Data" : "Add Data")+" "+_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{attrs:{"color":"primary mt-7","width":"30%"},on:{"click":function($event){_vm.isEditing ? _vm.submitData(true) : _vm.submitData(false)}}},[_vm._v(" "+_vm._s(_vm.isEditing ? "Edit Data" : "Add Data")+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.form),function(model,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[(
                              model.label !== 'Check' &&
                              model.label !== 'Plan'
                            )?_c('h2',{staticClass:"py-4 ps-2 text-left"},[_vm._v(" "+_vm._s(model.label)+" ")]):_vm._e(),(
                              model.type !== 'select' &&
                              model.type !== 'datetime' &&
                              model.type !== 'date' &&
                              model.label !== 'Check' &&
                              model.label !== 'Plan'
                            )?_c('v-text-field',{staticClass:"flex-grow-1 p-0",attrs:{"type":model.type == 'integer' ? 'number' : model.type,"rules":_vm.validateRules(model),"label":'Input the ' + model.type,"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF"},model:{value:(_vm.models[model.name]),callback:function ($$v) {_vm.$set(_vm.models, model.name, $$v)},expression:"models[model.name]"}}):_vm._e()],1)])}),1),_c('v-row',_vm._l((_vm.form),function(model,i){return _c('v-col',{key:i,staticClass:"p-0",attrs:{"cols":"12"}},[(
                            model.label != 'Year' &&
                            model.label != 'Check' &&
                            model.label != 'Title' &&
                            model.label != 'Issue & RCA' &&
                            model.label != 'Possible Cause'
                          )?_c('h2',{staticClass:"py-4 ps-2 text-left"},[_vm._v(" "+_vm._s(model.label)+" ")]):_vm._e(),(
                            model.label != 'Year' &&
                            model.label != 'Check' &&
                            model.label != 'Title' &&
                            model.label != 'Issue & RCA' &&
                            model.label != 'Possible Cause'
                          )?_c('v-text-field',{staticClass:"v-text-field-plan",attrs:{"type":model.type == 'integer' ? 'number' : model.type,"rules":_vm.validateRules(model),"label":'Input the ' + model.type,"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF"},model:{value:(_vm.models[model.name]),callback:function ($$v) {_vm.$set(_vm.models, model.name, $$v)},expression:"models[model.name]"}}):_vm._e()],1)}),1),_c('v-row',_vm._l((_vm.subForms),function(form,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[(!_vm.checkIfHasChild(form.label))?_c('v-text-field',{attrs:{"v-model":_vm.subFormsModels[form.model],"label":form.label}}):(form.label == 'Do')?_c('div',[_c('div',{staticClass:"d-flex flex-row justify-space-between ps-2"},[_c('h2',[_vm._v(_vm._s(form.label))]),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary radius mb-4","height":"37","width":"107","dark":""},on:{"click":function($event){form.label == 'Do'
                                  ? _vm.addDoForm(true)
                                  : _vm.console.log('do')}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Add "),_c('v-icon',{staticClass:"ms-2 align-center",attrs:{"size":"16\n                                "}},[_vm._v("mdi-plus")])],1)],1),_vm._l((form.children),function(childField,index){return _c('div',{key:index,class:index == 0 ? 'my-0' : 'my-3'},[_c('div',[_c('table',[_c('tr',[_vm._l((childField),function(child){return _c('th',{key:child.model},[_c('h4',{staticClass:"py-3 text-center white--text"},[_vm._v(" "+_vm._s(child.label)+" ")])])}),_c('th',[_c('h4',[_vm._v("Action")])])],2),_c('tr',[_vm._l((childField),function(child){return _c('td',{key:child.model},[(form.label == 'Do')?_c('div',[(child.type == 'text')?_c('v-text-field',{staticClass:"px-3 mt-5 v-text-field-ayolah",attrs:{"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF","type":child.type,"label":child.label},model:{value:(
                                          _vm.subFormsModels['do'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['do'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['do'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e(),(child.type == 'date')?_c('v-text-field',{staticClass:"px-3 mt-5",attrs:{"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF","type":child.type,"label":child.label},model:{value:(
                                          _vm.subFormsModels['do'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['do'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['do'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e(),(child.label == 'Pic')?_c('v-select',{staticClass:"px-3 pb-0 mt-5 v-text-field-select",attrs:{"solo":"","filled":"","flat":"","dense":"","background-color":"#EFEFEF","items":_vm.listUsers,"item-text":"username","item-value":"id","return-object":""},model:{value:(
                                          _vm.subFormsModels['do'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['do'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['do'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e(),(child.label == 'Status')?_c('v-select',{staticClass:"px-3 pb-0 mt-5 v-text-field-select",attrs:{"solo":"","filled":"","flat":"","dense":"","background-color":"#EFEFEF","items":_vm.listStatus,"item-text":"status","item-value":"id","return-object":""},on:{"change":function($event){return _vm.testChange()}},model:{value:(
                                          _vm.subFormsModels['do'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['do'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['do'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e()],1):_vm._e()])}),_c('td',{staticClass:"px-7"},[_c('v-btn',{staticClass:"elevation-0 px-3",attrs:{"width":"95","color":"primary radius","flat":""},on:{"click":function($event){form.label == 'Do'
                                          ? _vm.deleteDoForm(
                                              childField[0].id,
                                              index
                                            )
                                          : _vm.deleteActionForm(
                                              childField[0].id,
                                              index
                                            )}}},[_vm._v("Delete "),_c('v-icon',_vm._g(_vm._b({staticClass:"ms-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDialogDelete(_vm.item.id)}}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v(" mdi-delete ")])],1)],1)],2)])])])})],2):_vm._e()],1)}),1),_c('v-row',_vm._l((_vm.form),function(model,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[(model.label == 'Check')?_c('h2',{staticClass:"ps-2 text-left pt-6"},[_vm._v(" "+_vm._s(model.label)+" ")]):_vm._e(),(model.label == 'Check')?_c('v-text-field',{staticClass:"v-text-field-plan pb-4",attrs:{"type":model.type == 'integer' ? 'number' : model.type,"rules":_vm.validateRules(model),"label":'Input the ' + model.type,"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF"},model:{value:(_vm.models[model.name]),callback:function ($$v) {_vm.$set(_vm.models, model.name, $$v)},expression:"models[model.name]"}}):_vm._e()],1)}),1),_c('v-row',_vm._l((_vm.subForms),function(form,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[(!_vm.checkIfHasChild(form.label))?_c('v-text-field',{attrs:{"v-model":_vm.subFormsModels[form.model],"label":form.label}}):(form.label == 'Action')?_c('div',[_c('div',{staticClass:"d-flex flex-row justify-space-between ps-2"},[_c('h2',[_vm._v(_vm._s(form.label))]),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary radius mb-4","height":"37","width":"107","dark":""},on:{"click":function($event){form.label == 'Action'
                                  ? _vm.addActionForm(true)
                                  : _vm.console.log('Action')}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Add "),_c('v-icon',{staticClass:"ms-2 align-center",attrs:{"size":"16\n                                "}},[_vm._v("mdi-plus")])],1)],1),_vm._l((form.children),function(childField,index){return _c('div',{key:index,class:index == 0 ? 'my-0' : 'my-3'},[_c('div',[_c('table',[_c('tr',[_vm._l((childField),function(child){return _c('th',{key:child.model},[_c('h4',{staticClass:"py-3 text-center white--text"},[_vm._v(" "+_vm._s(child.label)+" ")])])}),_c('th',[_c('h4',[_vm._v("Action")])])],2),_c('tr',[_vm._l((childField),function(child){return _c('td',{key:child.model},[(form.label == 'Action')?_c('div',[(child.type == 'text')?_c('v-text-field',{staticClass:"px-3 mt-5 v-text-field-ayolah",attrs:{"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF","type":child.type,"label":child.label},model:{value:(
                                          _vm.subFormsModels['action'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['action'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['action'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e(),(child.type == 'date')?_c('v-text-field',{staticClass:"px-3 mt-5",attrs:{"solo":"","clearable":"","flat":"","dense":"","background-color":"#EFEFEF","type":child.type,"label":child.label},model:{value:(
                                          _vm.subFormsModels['action'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['action'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['action'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e(),(child.label == 'Pic')?_c('v-select',{staticClass:"px-3 pb-0 mt-5 v-text-field-select",attrs:{"solo":"","filled":"","flat":"","dense":"","background-color":"#EFEFEF","items":_vm.listUsers,"item-text":"username","item-value":"id","return-object":""},model:{value:(
                                          _vm.subFormsModels['action'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['action'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['action'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e(),(child.label == 'Status')?_c('v-select',{staticClass:"px-3 pb-0 mt-5 v-text-field-select",attrs:{"solo":"","filled":"","flat":"","dense":"","background-color":"#EFEFEF","items":_vm.listStatus,"item-text":"status","item-value":"id","return-object":""},on:{"change":function($event){return _vm.testChange()}},model:{value:(
                                          _vm.subFormsModels['action'][index][
                                            child.model
                                          ]
                                        ),callback:function ($$v) {_vm.$set(_vm.subFormsModels['action'][index], 
                                            child.model
                                          , $$v)},expression:"\n                                          subFormsModels['action'][index][\n                                            child.model\n                                          ]\n                                        "}}):_vm._e()],1):_vm._e()])}),_c('td',{staticClass:"px-7"},[_c('v-btn',{staticClass:"elevation-0 px-3",attrs:{"width":"95","color":"primary radius","flat":""},on:{"click":function($event){form.label == 'Action'
                                          ? _vm.deleteActionForm(
                                              childField[0].id,
                                              index
                                            )
                                          : _vm.console.log('Action')}}},[_vm._v("Delete "),_c('v-icon',_vm._g(_vm._b({staticClass:"ms-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDialogDelete(_vm.item.id)}}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v(" mdi-delete ")])],1)],1)],2)])])])})],2):_vm._e()],1)}),1)],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDialog(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-dialog',{attrs:{"width":"400","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                          var on = ref.on;
                                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openDialogDelete(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delete Data ")]),_c('v-card-text',[_vm._v(" Are you sure to delete this data? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteData(item.id)}}},[_vm._v(" Yes ")])],1)],1)],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }